.article-small p {
  padding-left: 162px;
}

div.article-icon-links-container {
  clear: both;
}

div.article-icon-links-container ul.article-icon-links li {
  padding: 0 5px 0 3px;
  float: left;
  list-style-type: none;
  white-space: nowrap;
}

div.article-icon-links-container ul.article-icon-links li.first {
    margin: 0;
    padding-left: 0;
}

div.article-icon-links-container ul.article-icon-links li a {
  color: black;
  line-height: 16px;
  position: relative;
  padding-right: 2px;
  _padding-right: 1px;
  display: block;
  _display: inline;
  z-index: 10;
}

div.article-icon-links-container ul.article-icon-links li a:hover {
  text-decoration: none;
  _text-decoration: underline;
  color: black;
}

div.article-icon-links-container ul.article-icon-links li a .icon {
  position: absolute;
  margin-top: 2px;
  /* top: 2px; - BREAKS IE8 */
  left: 0;
  height: 13px;
  width: 18px;
  background-color: transparent;
  background-image: url(/i/furniture/spt_previewlinks_12.png);
  _font-size: 1px;
  _line-height: 1px;
}

div.article-icon-links-container ul.article-icon-links li a.addstories-link .icon,
div.article-icon-links-container ul.article-icon-links li a.removestories-link .icon {
    width: 12px;
}

div.article-icon-links-container ul.article-icon-links li a.comments-link .icon {
    background-position: 0 0;
    width: 14px;
}

div.article-icon-links-container ul.article-icon-links li a.pictures-link .icon {
  background-position: -60px 0;
}

div.article-icon-links-container ul.article-icon-links li a.videos-link .icon {
  width: 20px;
  background-position: -98px 0;
}

div.article-icon-links-container ul.article-icon-links li a.share-link .icon {
  background-position: -201px 0;
}

div.article-icon-links-container ul.article-icon-links li a .linktext {
  padding-left: 22px;
  _display: inline-block;
}
div.article-icon-links-container ul.article-icon-links li a.videos-link .linktext {
  padding-left: 26px;
}
div.article-icon-links-container ul.article-icon-links li a:hover .linktext,
div.article-icon-links-container ul.article-icon-links li.referrer a:hover span {
  text-decoration: underline;
}

div.article-icon-links-container ul.article-icon-links li a.addstories-link .linktext,
div.article-icon-links-container ul.article-icon-links li a.removestories-link .linktext {
  padding-left: 15px;
}

div.article-icon-links-container ul.article-icon-links li.referrer img {
  margin-bottom: -1px;
}