ul.nav-primary {
  font-size: 1.5em;
  font-weight: bold;
  border-bottom: none;
  padding: 3px 0 0 0;
  background: url(/i/furniture/structure/navigation_bottom.gif) repeat-x 0 100%;
}
.page-header .nav-secondary,
.page-footer .nav-secondary {
  font-size: 1.2em;
  font-weight: bold;
  padding: 7px 0;
  border-top: none;
  border-right: none;
  border-bottom: none;
}
.page-header .border-top {
  border-top:1px solid #c0c0c0;
}
.page-header .nav-primary li,
.page-header .nav-secondary li,
.page-footer .nav-secondary li {
  position: relative;
  float: left;
}
.page-header.new .nav-secondary {
    font-size: 1.3em;
}
.page-header .nav-secondary li,
.page-footer .nav-secondary li  {
  border-left: 1px solid #fff;
  padding: 0 6px;
}
.page-header .nav-secondary li a.current,
.page-footer .nav-secondary li a.current {
  font-style:italic;
}
.page-header .nav-secondary li a:hover,
.page-footer .nav-secondary li a:hover {
  text-decoration:underline;
}
.nav-primary a {
  color: #333;
  display: block;
  padding: 4px 7px 7px;
  text-decoration: none;
  _float: left;
}
.nav-primary .link-gr6ox a,
.nav-primary .link-ccox a,
.nav-primary .linkro-ccox a {
    margin: 4px 0 7px;
    padding: 0 7px 0 6px;
    border-left: 1px solid #000;
}
.nav-primary li.first,
.page-header .nav-secondary li.first,
.page-footer .nav-secondary li.first{
  border-left: none;
}
.page-footer .nav-secondary a {
  border-right: 0;
  padding: 0;
}