/***** showcase *****/
.showcase {
  padding-bottom: 5px;
  margin-bottom:15px;
}
.showcase a,
.showcase a:hover {
  outline: none;
  text-decoration: none;
}
.showcase h1 {
  position: relative;
  font-size:2.5em;
  padding:5px 10px;
}
.showcase p {
  font-size: 1em;
  margin: 0;
}
.showcase .femail-text {
  font-size: 1.67em;
  display:block;
  padding: 5px 10px;
}
.showcase p.stanFont {
  padding: 10px;
}
.showcase .photos {
  float: left;
  width: 350px;
  height: 560px;
  position: relative;
}
.showcase .photos p.wocc {
  position: absolute;
  top: 0;
  left: 10px;
  width: 330px;
  height: 560px;
}
.showcase .photos.extras p.wocc {
  width: 221px;
}
.showcase .photos p.main img {
  position: absolute;
  top: 0;
  left: 0;
}
.showcase .photos p.wocc span {
  display:block;
  padding:7px 0pt;
  text-align:center;
}
.showcase .photos.extras p.wocc span {
  width: 201px;
}
.showcase .photos ul li {
  position: absolute;
  left: 330px;
}
.showcase .photos.extras ul li {
  left: 231px;
}
.showcase .photos ul li.extra1 {
  top: 0;
}
.showcase .photos ul li.extra2 {
  top: 190px;
}
.showcase .photos ul li.extra3 {
  top: 380px;
}
.showcase .products {
  float: right;
  _display:inline;
  width: 276px;
  margin-bottom: 5px;
  margin-right: 10px;
   position: relative;
   top: 0;
   left: 0;
   z-index: 1000;
}
.showcase .products .thumbnails,
.showcase .products .mainImages {
  margin: 0;
  padding: 0;
  font-size: 1em;
}
.showcase .products .thumbnails li {
  float: left;
  margin: 0;
  padding: 0;
  background-color: #fff;
  height: 190px;
  width: 138px;
  overflow: hidden;
}
.showcase .products .thumbnails li.productMain5,
.showcase .products .thumbnails li.productMain6 {
  height: 180px;
}
.showcase .products .thumbnails li img {
  display: block;
  margin: 0 10px;
  margin-bottom: 10px;
}
.showcase .products .thumbnails li p {
  padding: 10px;
  font-size: 0.9em !important;
}
.showcase .products .thumbnails li a {
  position: relative;
  display: block;
}
.showcase .products .thumbnails li a span {
  position: absolute;
  left: 0;
  font-weight: bold;
  font-size: 1.1em;
  top: 8.4em;
}
.showcase .products .thumbnails li a:hover {
  color: #000;
}
.showcase .products .mainImages li {
  background-color: #fff;
  border: 3px solid;
  border-color: #c0c0c0 #999999 #999999 #c0c0c0;
  position: absolute;
  width: 250px;
  z-index: 1000;
  font-size: 1em;
  visibility: hidden;
}
.showcase .products .mainImages li img {
  display: block;
  margin-bottom: 10px;
}
.showcase .products .mainImages li .product-container {
  margin: 10px;
  filter: alpha(opacity=0);
  opacity: 0;
  font-weight: bold;
}
.showcase .products .mainImages li a.close img {
  opacity: 1;
  position: absolute;
  right: 10px;
}
.showcase .products .mainImages li span {
  display: block;
}
.showcase .products .mainImages li .buy-link-container {
  margin: 12px 0;
  *margin: 12px 0 0;
  font-size: 1.2em;
  color: #fff;
}
.showcase .promo {
    float: right;
    margin: 0 0 5px 5px
}
