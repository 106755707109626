.puff h3 {
  font-weight: bold;
  font-size: 1.4em;
  text-transform: uppercase;
  padding: 4px 5px 2px;
  margin-bottom: 5px;
}
.puff a, .puff a:hover {
  display: block;
  min-height: 115px;
  _height: 115px;
  margin-bottom:5px;
}
.puff a .pufftext {
  display: block;
  padding: 5px 5px 5px 7px;
    width: 140px;
}
.puff a .pufftext span {
  margin-right: 3px;
  _margin-right: 0;
}
.puff a strong {
  display: block;
  color: #004db3;
}
.puff .linkro-wocc a:hover strong,
.puff .alt-wodg strong {
  color: #fff;
}

.puff a .arrow-small-r {
  color: #004db3;
}

.puff a:hover .arrow-small-r {
  color: #fff;
}

.puff .alt-wodg h3, .puff .alt-bogr2 h3 {
  margin-bottom: 0;
}

.puff .alt-femail h3 {
  background-image: url(/i/furniture/spt_femailpuf.gif);
  background-position: 0 -100px;
  font-size: 1.3em;
}
.puff .alt-femail a .pufftext {
  color: #999999;
  font-size: 1.2em;
}
.puff .alt-femail a .pufftext .arrow-small-r {
  color: #c0c0c0;
  margin-top: 5px;
}
.puff .alt-femail a strong {
  font-family: 'times new roman', arial, sans-serif;
  color: #c562a5;
  font-size: 1.2em;
}
.puff .alt-femail a:hover strong {
  color: #c562a5;
}
.royal_wedding .puff .alt-femail a strong,
.royal_wedding .puff .alt-femail a:hover strong {
    color: #540c75;
}
.puff .alt-femail a:hover,
.puff .alt-femail a:hover .pufftext {
  color: #666666;
}

.puff-advertorials a strong {
  color: #c0c0c0;
}
.puff-advertorials a:hover strong {
  color: #004db3;
}
.puff-advertorials a .pufftext .arrow-small-r {
  color: #e3e3e3;
}
.puff-advertorials a:hover .pufftext .arrow-small-r {
  color: #003580;
}

.puff img {
    float: right;
}

/* World News puff style */
.puff-wn img {
    float: left;
}
.puff-wn a .pufftext {
  padding-left: 161px;
}
.puff-wn .arrow-small-r {
  display:none;
}
.puff-wn a strong {
  color: #00AAD2;
}
.puff-ad {
  padding-bottom: 3px;
  padding-left: 4px;
}
.puff-ad img {
  float: none;
}
.puff-ad a {
  background-color: white;
}
.puff-ad.native-ad {
    padding: 0;
}
.puff-ad.native-ad small {
    background-color: #00aad2;
    color: #fff;
    line-height: 25px;
    padding-left: 7px;
    font-weight: bold;
}
.puff-ad.native-ad span {
    padding-bottom: 12px;
}
.puff-ad.native-ad .arrow-small-r {
    padding: 0;
}
.native-ad a, .native-ad a:hover {
    margin-bottom: 0;
    min-height: 140px;
}
.puff-ad.native-ad img {
    float: right;
}
.femail .linkro-wocc .puff-ad.native-ad a:hover {
    background-color: #C562A5;
}
.femail .wocc .puff-ad, .femail .link-wocc .puff-ad a, .femail .linkro-wocc .puff-ad a:hover {
    background-color: #fff;
}
