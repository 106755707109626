.page-header .weather {
  left:auto;
  right:0;
  width:450px;
  text-align:right;
  padding-top: 2px;
    display: none;
}
.page-header #weather-wrapper {
    display: block;
}
.page-header .weather a {
  color:#000;
}
.page-header .weather a:hover {
  text-decoration: underline;
}
.page-header .weather span {
  color:#666;
}
.page-header .weather span.last {
  color:#3366CC;
  font-weight:bold;
}
.page-header .icn-w {
  background-image: url(/i/furniture/weather/spt_weather_nav.png);
  font-size: 1px;
  line-height: 1px;
  width: 18px;
  height: 16px;
  position: absolute;
}

.page-header .sun, .page-header .clear, .page-header .hot{ background-position: 0 -2px; }
.page-header .ptcloudy{ background-position: -40px -2px; }
.page-header .mscloudy{ background-position: -80px -2px; }
.page-header .cloudy{ background-position: -120px -2px; }
.page-header .fog{ background-position: -160px -2px; }
.page-header .scshowers, .page-header .scatrain{ background-position: -200px -2px; }
.page-header .rain, .page-header .rainwindy{ background-position: -240px -2px; }
.page-header .heavyrain{ background-position: -280px 2px; }
.page-header .rainsnow, .page-header .rainicy, .page-header .icyrainsnow{ background-position: -320px -2px; }
.page-header .snowy, .page-header .snow, .page-header .flurries{ background-position: -360px -2px; }
.page-header .thunderstorm{ background-position: -400px -2px; }
.page-header .sunthund{ background-position: -440px -2px; }
.page-header .sunrain{ background-position: -480px -2px; }
