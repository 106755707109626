.image-strapline-small {
  height: 385px;
}
.image-strapline-small .link-text {
  left: 8px;
  bottom: 8px;
  width: 292px;
}
.image-strapline-small img {
  display: block;
}

.image-strapline, .image-strapline-large{
  _height: 1px;
}
.image-strapline .link-text {
  font-size: 1.6em;
  font-weight: bold;
  position: absolute;
  display: block;
}
.image-strapline .inner-text {
  display: block;
  padding: 6px;
}

.image-strapline-large {
  background-color: #000;
}
.image-strapline-large .link-text .bow {
  display: block;
  padding: 5px;
  font-size: 0.7em;
  font-weight: normal;
}
.image-strapline-large img {
  margin: 5px;
}
.image-strapline-large .link-text {
  left: 5px;
  bottom: 5px;
  width: 626px;
}
.image-strapline-large .inner-text {
  opacity: 0.9;
  filter:alpha(opacity=90);
  margin: 0 0 5px 0;
}
.image-strapline .link-ccob .slant-small-t {
  background-position: 0 0px;
}
.image-strapline .link-ccob .slant-small-b {
  background-position: -400px 0;
}