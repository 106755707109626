/* Article CSS should be included into the main CSS file*/


/* Article styles
    Advert styles in adverts.css
*/

.rss-feeds {
  font-size: 12px;
}

.x-layout-container .articlePage {
    margin:10px;
}

.alpha .article-text {
    width: 636px;
    min-height:550px;
    _height:550px;
}
.article-text div.article-icon-links-container {
    clear: none;
}
.article-text ul.article-icon-links {
    padding:3px 0;
    min-height: 25px;
    _height: 25px;
    font-size: 1.4em; /* increase icon text to 14px on article pages only */
    margin-bottom: 0;
}
.article-text ul.article-icon-links li {
    margin-bottom: 8px;
}
.article-text a.inline-link {
    color: #000;
}

.artItem a.inline-link {
    color: #000;
}

.pageBreak {
    clear:both;
    height:0px;
    background:none;
    border:0;
    border-bottom:2px dotted #333;
    margin: 10px 20px;
    line-height:1px;
    _height:2px;
    _border:0;
    _background:url(/i/furniture/articles/2pxDots.gif) repeat-x bottom;
}

.article-advert {
    background: url(/i/furniture/articles/greyBackg.gif) repeat-y;
    min-height: 950px;
    _height: 950px;
}

.article-advert .article-advert-body {
    float: right;
    width: 798px;
    position: relative;
}

.article-advert .article-text {
    width: 470px;
    margin-right: 20px;
    _overflow-x: hidden;
}

.floatRHS, .floatLHS,.floatThirdRHS, .floatThirdLHS {
    width:308px;
    float:right;
    _display:inline;
    margin-bottom:10px;
}

.floatRHS, .floatThirdRHS {
    margin-left: 20px;
}

.floatLHS, .floatThirdLHS {
    float:left;
    margin-left:0;
    margin-right:10px;
}

.floatThirdRHS, .floatThirdLHS {
    width:198px;
}

img.blkBorder {
    border:1px solid black;
}

img.img-no-border {
    border:1px solid white;
}

.eightySevenLHS, .eightySevenRHS {
    width:89px;
    float:left;
    margin:0 4px 4px 0;
}

.eightySevenRHS {
    float:right;
    margin:0 0 4px 4px;
}

.thinFloatRHS,.thinFloatLHS,.legacyImageFloatRHS {
    width:235px;
    margin-left:10px;
    float:right;
    _display:inline;
    margin-bottom:4px;
}

.legacyImageFloatRHS {
    width:230px;
}

.thinCenter {
    width:470px;
    margin:0 auto;
}

.thinFloatLHS {
    float:left;
    margin-right:10px;
    margin-left:0;
}

.article-text .thinArtSplitter, .article-text .xwArtSplitter {
    margin-bottom:4px;
}
.article-text .artSplitter {
    margin:0 auto 4px;
    width:636px;
}

.article-text .artSplitter .splitLeft,.article-text .artSplitter .splitRight {
    float:left;
    width:308px;
}

.article-text .artSplitter .splitRight,
.article-text .xwArtSplitter .splitRight {
    margin-left:20px;
}

.article-text .art-ins .artSplitter .splitRight,
.article-text .art-ins .xwArtSplitter .splitRight {
    margin-left:10px;
}

.article-text .artThinSplitter .splitLeft,.article-text .artThinSplitter .splitRight {
    float:left;
    width:144px;
}

.article-text .artThinSplitter .splitRight {
    margin-left:20px;
}


.floatXWHalfRHS {
    float:right;
    margin-left:20px;
    width:472px;
}

.floatXWHalfLHS {
    float:left;
    margin-right:20px;
    width:472px;
}

.article-text .thinArtSplitter .splitLeft,.article-text .thinArtSplitter .splitRight {
    float:left;
    width:226px;
}

.article-text .thinArtSplitter .splitRight {
    margin-left:18px;
}

.article-text .art-ins .thinArtSplitter .splitRight {
    margin-left:9px;
}

.article-text .xwArtSplitter .splitLeft,.article-text .xwArtSplitter .splitRight {
    float:left;
    width:472px;
}

.article-text .artSplitter .quarter {
    float:left;
    margin-left:12px;
    width:150px;
}

.article-text .xwArtSplitter .quarter {
    float:left;
    margin-left:12px;
    width:232px;
}


.article-text .quarter.first,
.article-text .xwArtSplitter .quarter.first {
    margin-left:0 !important;
}

.article-text .MPU {
    width:300px;
    height:250px;
    margin:4px;
    margin-bottom:10px;
    float:right;
    display:block;
}

.article-text .artSplitter .splitRight .MPU,.article-text .floatRHS .MPU {
    float:none;
    margin:0 auto;
    margin-bottom:10px;
}

.feMailHeaderWide {
    background:#c562a5;
    color:white;
    padding: 2px 8px 4px 8px;
    margin-bottom:4px;
}


.feMailHeaderWide a {
    color:white;
    text-decoration:underline;
}

.feMailHeaderWide a:hover {
    color:#f0f0f0;
}

.feMailHeaderWide h1 {
    font-size:3em;
    font-family:Times New Roman;
    margin-bottom:14px !important;
    /*line-height:0.8em;*/
    padding:4px 0 0 0;
}

.feMailHeaderWide h2 {
    font-size:1.6em;
    margin-bottom:0 !important;
    padding:0;
}
/* hot tropic is the larger version of the femail header, needs femailheaderwide class to function */

.hotTropicWide {
    min-height:200px;
    _height:200px;
    margin-bottom:20px;
}

.twoThirds .hotTropicWide {
    min-height:223px;
    _height:223px;
}

.article-text .hotTropicWide h1 {
    font-size:6.0em;
    font-weight:500;
}

div.femailQuote {
    padding:15px 8px 8px 40px;
    background:#c562a5 url(/i/furniture/articles/femailQuotLeft.gif) no-repeat 8px 8px;
    font-family:arial;
    color:white;
}

.article-text div.femailQuote h4 {
    font-size:2.4em;
    font-weight:normal;
}

div.femailQuote p {
    font-size:1.1em;
    padding:0;
    margin:0;
}

span.femailQuotRight {
    margin: 0 0 -20px 0;
    background:url(/i/furniture/articles/femailQuotRight.gif) no-repeat;
    width:26px;
    height:24px;
    vertical-align:sub;
    line-height:0;
}

div.beautyConfidentialHeader {
    background:#000 url(/i/furniture/articles/beautyConfidential.jpg) no-repeat;
    height:130px;
}

div.mrsCreditCrunchHeader {
    background:#000 url(/i/furniture/articles/bn_mrscreditcrunch.gif) no-repeat;
    height:130px;
}



.article-text .third {
    width:198px;
    float:left;
    margin-left:21px;
}

.article-text .thinArtSplitter .third {
    width:150px;
    margin-left:10px;
}

.article-text .xwArtSplitter .third {
    float:left;
    margin-left:17px;
    width:310px;
}

.article-text .third.first,
.article-text .thinArtSplitter .third.first {
    margin-left:0 !important;
}

.article-text img.imageEdit{
    display:block;
}

.imageQuadHolder {
    float:left;
    width:432px;
    min-height:100px;
    margin-bottom:20px;
}

.imageQuadHolder img,
.imageQuadHolder div.relative {
    float:left;
    margin-right:9px;
    _display:inline;
}

.imageQuadHolder div img {
    float:none;
    margin-right:0;
}

.imageLongHolder {
    float:left;
    width:204px;
    min-height:100px;
    margin-bottom:20px;
}


div.flt {
    float:left;
    margin-bottom:20px;
}

.oneThird {
    width:212px;
    float:left;
    margin:0 20px 20px 0;
    _display:inline;
}

.twoThirds{
    width:404px;
    float:left;
}
.alphaWidth {
    width: 636px;
    float: left;
}
.alphaWidth .hotTropicWide {
    min-height: 222px;
    _height: 222px;
}
.betaWidth {
    width: 308px;
    float: left;
    margin: 0 20px 20px 0;
    _display: inline;
}
.top20{
    margin-top:20px;
}

.article-text .bot8:after,
.article-text .bot20:after,
.article-text .left20:after,
.article-text .right20:after {
    content: ".";
    display: block;
    height: 0 !important;
    clear: both;
    visibility: hidden;
}

.article-text .bot8 {
    margin-bottom:8px;
}

.bot20, .article-text .bot20{
    margin-bottom:20px;
}

.bot30 {
    margin-bottom:30px;
}

.left20,.article-text .left20{
    margin-left:20px;
}

.right20,.article-text .right20{
    margin-right:20px;
}

.googleAds {
    padding:10px 0;
    text-align:center;
}

h2.MS_MainHeadlineText_padding a {
    color: white !important; font-size: 20px !important;
}

h2.articleHeader {
    background-color: transparent !important;
    width: 308px !important;
    height: auto !important;
    display: block !important;
    font-size: 16px !important;
    line-height: normal;
    margin-bottom: 7px;
}

h2.articleHeader a {
    color: #004db3 !important;
    font-size: 16px !important;
    font-weight: bold !important;
}

h2.articleHeader a:hover {
    color: #c00 !important;
}
/*article preview related links*/
ul.relatedArticleLinks {
    clear:both;
    list-style-type:none;
    margin:5px 0 0 0;
    padding:0;
}
ul.relatedArticleLinks li {
    background: transparent url(/i/furniture/li_headlines.gif) no-repeat 0 -2px;
    padding:0 0 0 15px;
    font-weight:bold;
}

/* PRINT, READ LATER, EMAIL TO FRIEND LINKS - TODO: check if .print-or-mail-links is used */

.print-or-mail-links {
    margin-bottom: 25px;
}
.print-or-mail-links div {
    font-size: 1.2em;
    width: 33%;
}
.print-or-mail-links div img {
    vertical-align: middle;
    margin-right: 2px;
}

/*column content layout */
.column-content{
    margin:25px 0 10px 0;
}

/* TM styles */
.TMHero {
    width:468px;
    margin:0 auto 10px auto;
}

/* Youtube video */
.youtube-video {
    margin:10px 0;
}

.art-ins,
.art-insert {
    min-height:50px;
    _height:50px;
}

.art-insert {
    width:235px;
    float:right;
    margin-left:10px;
}

.art-ins h3,
.art-insert h3 {
    padding:5px;
    font-size: 20px;
    line-height: 24px;
    margin:0;
    font-weight:normal;
}

.gamma .art-ins h3,
.gamma .art-insert h3 {
    font-size: 22px;
    line-height: 26px;
}

.art-ins .ins,
.art-insert .ins {
    padding:4px;
}

/* Article styles for TIMTOMOL*/
.floatTimLHS {
    float: left;
    margin: 0 10px 10px 0;
    width: 203px;
}
.floatTimRHS {
    float: right;
    margin: 0 0 10px 10px;
    width: 203px;
}

.timListGroup {
    clear: both;
}
.timListGroup img {
    float: right;
    margin: 10px;
}
.timListTitle {
    font-weight: bold;
}
.artTimDefault {
    text-align: center;
    margin: 0 0 10px 0;
}
.artTimDefault img {
    margin: 0 auto;
}

.article-text .geo-unavailable {
    margin: 0 0 4px 0;
    line-height:normal;
    font-size: 3em;
    font-weight: bolder;
}

.add-comment a {
    width: 153px !important;
    height: 40px !important;
    background: url(/i/furniture/articles/Add_Comment_btn.png) !important;
}

#addCommentNarrowButton {
    float: right;
    width: 153px !important;
    margin-right: 0;
    height: 40px;
    padding-left: 10px;
}

#addCommentButton {
    float: right;
    width: 153px !important;
    margin-right: 0;
    height: 40px;
    padding-left: 10px;
}

div.article-headline {
    background-color: #F5F5F5;
    padding-top: 10px;
    padding-bottom: 16px;
    margin-bottom: 0px;
}

/*
author twitter button
*/
.article-text .follow-author{
    display: inline-block;
    height: 13px;
    width: 15px;
    background: url(//i.dailymail.co.uk/i/furniture/icons/twitter_icon_tiny.png) no-repeat;
    vertical-align: text-bottom;
    padding-bottom: 1px;
}
/*avoid fuoc on twitter button*/
.article-text .follow-author a.twitter-follow-button{
    display: none;
}

.article-text .follow-author .twitter-follow-button{
    position: relative;
    top: 5px;
}

/*
temporary fix for by line section
*/

.article-timestamp .article-timestamp-label{
    text-transform: uppercase;
    font-weight: bold;
}

body .article-text .mol-style-subhead {
  font-family: graphik, arial, sans-serif;
  font-weight: 700;
  font-size: 24px !important;
  line-height: 28px;
}
