/* reset article styles first */
.article-text .rss-feeds p {
    font-size: 1em;
}
.article-text .rss-feeds ul {
    padding: 0;
    list-style-type: none;
    font-size: 1em;
}
.article-text .rss-feeds li {
    padding: 0;
    list-style-type: none;
}
/* */
.rss-feeds .intro {
    margin-bottom: 15px;
}
.rss-feeds .intro div {
    margin-right: 20px;
    display: block;
    float: left;
    width: 308px;
}
.rss-feeds .intro .last {
    margin-right: 0; 
}
.rss-feeds .intro li {
    margin-top: 5px;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    width: 151px;
}
.rss-feeds .intro a {
    font-weight: bold;
    color: #004DB3;
}
.rss-feeds .intro a:hover {
    color: #004DB3;
}
.rss-feeds .box {
    padding: 15px 15px 7px;
    background-color: #edf2fa;
}
.rss-feeds h2 {
    margin-bottom: 8px;
    font-size: 1.16em;
    font-weight: bold;
}
.rss-feeds h3,
.rss-feeds h4  {
    font-size: 1.4em;
    font-weight: bold;
    padding: 4px 5px 2px;
    text-transform: uppercase;
}
.rss-feeds p {
    margin: 8px 0;
    line-height: 1.25em;
}
.rss-feeds .rss-group {
    margin: 8px 0 15px;
    border-top: 1px solid #e3e3e3;
}
.rss-feeds .rss-group li {
    padding: 10px 0;
    border-bottom: 1px solid #e3e3e3;
    text-align: right;
}
.rss-feeds .rss-title {
    float: left;
    font-weight: bold;
    line-height: 20px;
}
.rss-feeds .rss-btn {
    margin-left: 7px;
}

