.rss_feeds_buttons {
    padding: 10px;
    border: 2px solid #e3e3e3;
}
.rss_feeds_buttons h2 {
    font-size: 1.4em;
    font-weight: bold;
}
.rss_feeds_buttons ul {
    margin: 8px 0 0;
    width: 294px;
}
.rss_feeds_buttons li {
    margin: 0 7px 10px 0;
    width: 137px;
}
.rss_feeds_buttons .more {
    font-color: #004DB3;
    font-weight: bold;
}

/* re-usable rss buttons */
.rss-btn {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}
.rss-btn a {
    display: block;
    padding: 0 6px 0 26px;
    height: 20px;
    line-height: 20px;
    color: #fff !important;
    font-size: 1em;
    font-weight: normal;
    background-image: url("//i.dailymail.co.uk/i/furniture/standard_modules/rss_feeds_buttons/rss_buttons.png");
    background-position: 0 0;
}
.rss-btn.my-yahoo a {
    background-position: -274px 0;
}
.rss-btn.feedly a {
    background-position: -137px 0;
}
.rss-btn.aol-reader a {
    background-position: -411px 0;
}
.rss-btn a:hover {
    text-decoration: none !important;
    color: #fff !important;
}
.rss-btn.rss a:hover {
    background-position: 0 -20px;
}
.rss-btn.my-yahoo a:hover {
    background-position: -274px -20px;
}
.rss-btn.feedly a:hover {
    background-position: -137px -20px;;
}
.rss-btn.aol-reader a:hover {
    background-position: -411px -20px;;
}
