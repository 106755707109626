.and-footer {
  text-align: center;
  font-size: 1.1em;
  margin: 15px 0;
}
.and-footer a {
  padding-right:15px;
}
.and-footer a:hover {
  text-decoration: underline;
}
.and-footer img {
  display: block;
  margin: 0 auto;
}
.and-footer .last {
    padding-right:0;
}
.and-footer .copyRight{
    display: block;
    padding: 10px 0 0 0;
}
.and-footer #glam_logo{
    padding: 10px 0 0 0;
}
.and-footer .cookies img {
    display: inline-block;
    vertical-align: text-bottom;
    margin-left: -8px;
}