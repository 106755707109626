.mirrormirror {
  position: relative;
  background-color: #f5f5f5;
}
.mirrormirror a,
.mirrormirror a:hover {
  outline: none;
  text-decoration: none;
}
.mirrormirror h3 {
  font-size: 1.4em;
  padding: 4px 6px 2px;
}
.mirrormirror h3 span{
  font-weight: normal;
  font-size: .8em;
}
.mirrormirror p {
  font-size: 1em;
  margin: 0;
}
.mirrormirror .mirror-container,
.mirrormirror .mirror-column .mirror-overlay .opaque {
  position:relative;
}
.mirrormirror .mirror-content {
  position:absolute;
  left:156px;
  width:294px;
  top:0;
}
.mirrormirror .mirror-content .subheading-l,
.mirrormirror .mirror-content .subheading-r {
  float:left;
  width:131px;
  text-align:right;
  color:#c562a5;
  font-size:1.2em;
  padding:2px 15px;
}
.mirror-content .subheading {
  float: left;
  width: 325px;
}
.mirrormirror .mirror-content .subheading-r {
  text-align:left;
}
.mirrormirror .mirror-row {
  padding:5px 38px;
  min-height:100px;
  _height:100px;
}
.mirrormirror .mirror-column {
  float:left;
  margin:0;
  position: relative;
  width: 98px;
}
.mirrormirror .column1 {
  z-index: 305;
}
.mirrormirror .column2 {
  z-index: 304;
}
.mirrormirror .column3 {
  z-index: 303;
}
.mirrormirror .column4 {
  z-index: 302;
}
.mirrormirror .column5 {
  z-index: 301;
}
.mirrormirror .mirror-column span.ccox {
  position: absolute;
  left: 3px;
  font-weight: bold;
  font-size: 1em;
  bottom: 1px;
}
.mirrormirror .mirror-column.float-r {
  float:right;
}
.mirrormirror .mirror-column a.mirror-image-link {
  display: block;
  border:4px solid #f5f5f5;
  position: relative;
}
.mirrormirror .mirror-column a.mirror-image-link:hover {
  border:4px solid #ccc;
}
.mirrormirror .mirror-column img.mirror-image {
  display:block;
  margin:0;
}
.mirrormirror .mirror-column .mirror-overlay {
  visibility:hidden;
  width:180px;
  position:absolute;
  left:-28px;
  top:-4px;
  background-color:#fff;
  border-top: 3px solid #ccc;
  border-left: 3px solid #ccc;
  border-right: 3px solid #999;
  border-bottom: 3px solid #999;
  padding: 10px;
  overflow: hidden;
  z-index:400;
  _height:auto;
}
.mirrormirror .mirror-column .mirror-overlay .closebutton {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor:pointer;
}
.mirrormirror .mirror-column.float-r .mirror-overlay { left:-80px; }
.mirrormirror p.gr4ox {
  padding: 5px;
}
.mirrormirror .mirror-column .mirror-overlay p {
  margin-top: 4px;
}
.mirrormirror .mirror-column .mirror-overlay .buy-link-container {
  margin: 12px 0 4px;
  *margin: 12px 0 0;
}