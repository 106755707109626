.pufflinks h3 {
  font-size: 1.9em;
  padding: 1px 5px 0;
  margin-bottom: 5px;
}
.pufflinks .pufflinks-main {
  padding: 8px;
  background-color: #000;
}
.pufflinks .title {
  font-size: 1.2em;
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}
.pufflinks div.link-box {
  display: inline;
}
.pufflinks .pufflinks-content {
  display: block;
  margin-bottom: 4px;
  background-repeat: no-repeat;
  min-height: 84px;
  _height: 84px;
  padding-left: 92px;
}
.pufflinks .pufflinks-content span {
  display: block;
  padding: 2px 5px 3px 0;
}
.pufflinks .separator {
  height: 2px;
  margin: 5px 0 8px;
  _font-size: 1px;
  _line-height: 1px;
}

/**** OVERRIDE THE STANDARD PREVIEW LINK IMAGES ****/
.pufflinks div.article-icon-links-container ul.article-icon-links li {
    border-color: #999;
}
.pufflinks div.article-icon-links-container ul.article-icon-links li span {
    color: #999;
}
.pufflinks div.article-icon-links-container ul.article-icon-links li .icon {
    background-color: #999;
    background-image: url(/i/furniture/spt_previewlinks_11.png);
    width: 19px !important;
    height: 12px;
}
.pufflinks div.article-icon-links-container ul.article-icon-links li a.comments-link .icon  {
    background-position: -30px -20px;
}
.pufflinks div.article-icon-links-container ul.article-icon-links li a.videos-link .icon {
    background-position: -90px -20px;
}
.pufflinks div.article-icon-links-container ul.article-icon-links li a.addstories-link .icon {
    background-position: 0 -20px;
}
.pufflinks div.article-icon-links-container ul.article-icon-links li a.pictures-link .icon {
    background-position: -60px -20px;
}
