/**
 * Basic (typography) classes
 *
 * KEYS
 * o = on
 * w = white
 * b = black
 * grN = grey colour
 * x = transparent
 * cc = channnel colour
 * lcc = light channel colour
 * link = link colour
 * linkro = rollover
 *
 * i.e.
 * .sport .ccox - sport colour text on transparent background
 *
 */
.coffeebreak-ccox {
  color: #823d1e; }

.coffeebreak .wocc, .coffeebreak .link-wocc a, .coffeebreak .linkro-wocc a:hover {
  color: #fff;
  background-color: #823d1e; }

.coffeebreak .bocc, .coffeebreak .link-bocc a, .coffeebreak .linkro-bocc a:hover {
  color: #000;
  background-color: #823d1e; }

.coffeebreak .xocc, .coffeebreak .link-xocc a, .coffeebreak .linkro-xocc a:hover {
  background-color: #823d1e; }

.coffeebreak .xolcc, .coffeebreak .link-xolcc a, .coffeebreak .linkro-xolcc a:hover {
  background-color: #ffeee7; }

.coffeebreak .lccox, .coffeebreak .link-lccox a, .coffeebreak .linkro-lccox a:hover {
  color: #ffeee7; }

.coffeebreak .ccow, .coffeebreak .link-ccow a, .coffeebreak .linkro-ccow a:hover {
  color: #823d1e;
  background-color: #fff; }

.coffeebreak .ccob, .coffeebreak .link-ccob a, .coffeebreak .linkro-ccob a:hover {
  color: #823d1e;
  background-color: #000; }

.coffeebreak .ccox, .coffeebreak .link-ccox a, .coffeebreak .linkro-ccox a:hover {
  color: #823d1e; }

.coffeebreak .ccolcc, .coffeebreak .link-ccolcc a, .coffeebreak .linkro-ccolcc a:hover {
  color: #823d1e;
  background-color: #ffeee7; }

.coffeebreak .ccolcc, .coffeebreak .link-ccolcc a, .coffeebreak .linkro-ccolcc a:hover {
  color: #823d1e;
  background-color: #e3e3e3; }

.coffeebreak .ccogr1, .coffeebreak .link-ccogr1 a, .coffeebreak .linkro-ccogr1 a:hover {
  color: #823d1e;
  background-color: #f5f5f5; }

.coffeebreak .ccogr2, .coffeebreak .link-ccogr2 a, .coffeebreak .linkro-ccogr2 a:hover {
  color: #823d1e;
  background-color: #e3e3e3; }

.coffeebreak .ccogr3, .coffeebreak .link-ccogr3 a, .coffeebreak .linkro-ccogr3 a:hover {
  color: #823d1e;
  background-color: #c0c0c0; }

.coffeebreak .ccogr4, .coffeebreak .link-ccogr4 a, .coffeebreak .linkro-ccogr4 a:hover {
  color: #823d1e;
  background-color: #999; }

.coffeebreak .ccogr5, .coffeebreak .link-ccogr5 a, .coffeebreak .linkro-ccogr5 a:hover {
  color: #823d1e;
  background-color: #666; }

.coffeebreak .ccogr6, .coffeebreak .link-ccogr6 a, .coffeebreak .linkro-ccogr6 a:hover {
  color: #823d1e;
  background-color: #333; }

.dailymailtv-ccox {
  color: #004db3; }

.dailymailtv .wocc, .dailymailtv .link-wocc a, .dailymailtv .linkro-wocc a:hover {
  color: #fff;
  background-color: #004db3; }

.dailymailtv .bocc, .dailymailtv .link-bocc a, .dailymailtv .linkro-bocc a:hover {
  color: #000;
  background-color: #004db3; }

.dailymailtv .xocc, .dailymailtv .link-xocc a, .dailymailtv .linkro-xocc a:hover {
  background-color: #004db3; }

.dailymailtv .xolcc, .dailymailtv .link-xolcc a, .dailymailtv .linkro-xolcc a:hover {
  background-color: #e8f3ff; }

.dailymailtv .lccox, .dailymailtv .link-lccox a, .dailymailtv .linkro-lccox a:hover {
  color: #e8f3ff; }

.dailymailtv .ccow, .dailymailtv .link-ccow a, .dailymailtv .linkro-ccow a:hover {
  color: #004db3;
  background-color: #fff; }

.dailymailtv .ccob, .dailymailtv .link-ccob a, .dailymailtv .linkro-ccob a:hover {
  color: #004db3;
  background-color: #000; }

.dailymailtv .ccox, .dailymailtv .link-ccox a, .dailymailtv .linkro-ccox a:hover {
  color: #004db3; }

.dailymailtv .ccolcc, .dailymailtv .link-ccolcc a, .dailymailtv .linkro-ccolcc a:hover {
  color: #004db3;
  background-color: #e8f3ff; }

.dailymailtv .ccolcc, .dailymailtv .link-ccolcc a, .dailymailtv .linkro-ccolcc a:hover {
  color: #004db3;
  background-color: #e3e3e3; }

.dailymailtv .ccogr1, .dailymailtv .link-ccogr1 a, .dailymailtv .linkro-ccogr1 a:hover {
  color: #004db3;
  background-color: #f5f5f5; }

.dailymailtv .ccogr2, .dailymailtv .link-ccogr2 a, .dailymailtv .linkro-ccogr2 a:hover {
  color: #004db3;
  background-color: #e3e3e3; }

.dailymailtv .ccogr3, .dailymailtv .link-ccogr3 a, .dailymailtv .linkro-ccogr3 a:hover {
  color: #004db3;
  background-color: #c0c0c0; }

.dailymailtv .ccogr4, .dailymailtv .link-ccogr4 a, .dailymailtv .linkro-ccogr4 a:hover {
  color: #004db3;
  background-color: #999; }

.dailymailtv .ccogr5, .dailymailtv .link-ccogr5 a, .dailymailtv .linkro-ccogr5 a:hover {
  color: #004db3;
  background-color: #666; }

.dailymailtv .ccogr6, .dailymailtv .link-ccogr6 a, .dailymailtv .linkro-ccogr6 a:hover {
  color: #004db3;
  background-color: #333; }

.debate-ccox {
  color: #a31414; }

.debate .wocc, .debate .link-wocc a, .debate .linkro-wocc a:hover {
  color: #fff;
  background-color: #a31414; }

.debate .bocc, .debate .link-bocc a, .debate .linkro-bocc a:hover {
  color: #000;
  background-color: #a31414; }

.debate .xocc, .debate .link-xocc a, .debate .linkro-xocc a:hover {
  background-color: #a31414; }

.debate .xolcc, .debate .link-xolcc a, .debate .linkro-xolcc a:hover {
  background-color: #fff6f6; }

.debate .lccox, .debate .link-lccox a, .debate .linkro-lccox a:hover {
  color: #fff6f6; }

.debate .ccow, .debate .link-ccow a, .debate .linkro-ccow a:hover {
  color: #a31414;
  background-color: #fff; }

.debate .ccob, .debate .link-ccob a, .debate .linkro-ccob a:hover {
  color: #a31414;
  background-color: #000; }

.debate .ccox, .debate .link-ccox a, .debate .linkro-ccox a:hover {
  color: #a31414; }

.debate .ccolcc, .debate .link-ccolcc a, .debate .linkro-ccolcc a:hover {
  color: #a31414;
  background-color: #fff6f6; }

.debate .ccolcc, .debate .link-ccolcc a, .debate .linkro-ccolcc a:hover {
  color: #a31414;
  background-color: #e3e3e3; }

.debate .ccogr1, .debate .link-ccogr1 a, .debate .linkro-ccogr1 a:hover {
  color: #a31414;
  background-color: #f5f5f5; }

.debate .ccogr2, .debate .link-ccogr2 a, .debate .linkro-ccogr2 a:hover {
  color: #a31414;
  background-color: #e3e3e3; }

.debate .ccogr3, .debate .link-ccogr3 a, .debate .linkro-ccogr3 a:hover {
  color: #a31414;
  background-color: #c0c0c0; }

.debate .ccogr4, .debate .link-ccogr4 a, .debate .linkro-ccogr4 a:hover {
  color: #a31414;
  background-color: #999; }

.debate .ccogr5, .debate .link-ccogr5 a, .debate .linkro-ccogr5 a:hover {
  color: #a31414;
  background-color: #666; }

.debate .ccogr6, .debate .link-ccogr6 a, .debate .linkro-ccogr6 a:hover {
  color: #a31414;
  background-color: #333; }

.event-ccox {
  color: #00b1a6; }

.event .wocc, .event .link-wocc a, .event .linkro-wocc a:hover {
  color: #fff;
  background-color: #00b1a6; }

.event .bocc, .event .link-bocc a, .event .linkro-bocc a:hover {
  color: #000;
  background-color: #00b1a6; }

.event .xocc, .event .link-xocc a, .event .linkro-xocc a:hover {
  background-color: #00b1a6; }

.event .xolcc, .event .link-xolcc a, .event .linkro-xolcc a:hover {
  background-color: #eee; }

.event .lccox, .event .link-lccox a, .event .linkro-lccox a:hover {
  color: #eee; }

.event .ccow, .event .link-ccow a, .event .linkro-ccow a:hover {
  color: #00b1a6;
  background-color: #fff; }

.event .ccob, .event .link-ccob a, .event .linkro-ccob a:hover {
  color: #00b1a6;
  background-color: #000; }

.event .ccox, .event .link-ccox a, .event .linkro-ccox a:hover {
  color: #00b1a6; }

.event .ccolcc, .event .link-ccolcc a, .event .linkro-ccolcc a:hover {
  color: #00b1a6;
  background-color: #eee; }

.event .ccolcc, .event .link-ccolcc a, .event .linkro-ccolcc a:hover {
  color: #00b1a6;
  background-color: #e3e3e3; }

.event .ccogr1, .event .link-ccogr1 a, .event .linkro-ccogr1 a:hover {
  color: #00b1a6;
  background-color: #f5f5f5; }

.event .ccogr2, .event .link-ccogr2 a, .event .linkro-ccogr2 a:hover {
  color: #00b1a6;
  background-color: #e3e3e3; }

.event .ccogr3, .event .link-ccogr3 a, .event .linkro-ccogr3 a:hover {
  color: #00b1a6;
  background-color: #c0c0c0; }

.event .ccogr4, .event .link-ccogr4 a, .event .linkro-ccogr4 a:hover {
  color: #00b1a6;
  background-color: #999; }

.event .ccogr5, .event .link-ccogr5 a, .event .linkro-ccogr5 a:hover {
  color: #00b1a6;
  background-color: #666; }

.event .ccogr6, .event .link-ccogr6 a, .event .linkro-ccogr6 a:hover {
  color: #00b1a6;
  background-color: #333; }

.femail-ccox {
  color: #c562a5; }

.femail .wocc, .femail .link-wocc a, .femail .linkro-wocc a:hover {
  color: #fff;
  background-color: #c562a5; }

.femail .bocc, .femail .link-bocc a, .femail .linkro-bocc a:hover {
  color: #000;
  background-color: #c562a5; }

.femail .xocc, .femail .link-xocc a, .femail .linkro-xocc a:hover {
  background-color: #c562a5; }

.femail .xolcc, .femail .link-xolcc a, .femail .linkro-xolcc a:hover {
  background-color: #f7e7ff; }

.femail .lccox, .femail .link-lccox a, .femail .linkro-lccox a:hover {
  color: #f7e7ff; }

.femail .ccow, .femail .link-ccow a, .femail .linkro-ccow a:hover {
  color: #c562a5;
  background-color: #fff; }

.femail .ccob, .femail .link-ccob a, .femail .linkro-ccob a:hover {
  color: #c562a5;
  background-color: #000; }

.femail .ccox, .femail .link-ccox a, .femail .linkro-ccox a:hover {
  color: #c562a5; }

.femail .ccolcc, .femail .link-ccolcc a, .femail .linkro-ccolcc a:hover {
  color: #c562a5;
  background-color: #f7e7ff; }

.femail .ccolcc, .femail .link-ccolcc a, .femail .linkro-ccolcc a:hover {
  color: #c562a5;
  background-color: #e3e3e3; }

.femail .ccogr1, .femail .link-ccogr1 a, .femail .linkro-ccogr1 a:hover {
  color: #c562a5;
  background-color: #f5f5f5; }

.femail .ccogr2, .femail .link-ccogr2 a, .femail .linkro-ccogr2 a:hover {
  color: #c562a5;
  background-color: #e3e3e3; }

.femail .ccogr3, .femail .link-ccogr3 a, .femail .linkro-ccogr3 a:hover {
  color: #c562a5;
  background-color: #c0c0c0; }

.femail .ccogr4, .femail .link-ccogr4 a, .femail .linkro-ccogr4 a:hover {
  color: #c562a5;
  background-color: #999; }

.femail .ccogr5, .femail .link-ccogr5 a, .femail .linkro-ccogr5 a:hover {
  color: #c562a5;
  background-color: #666; }

.femail .ccogr6, .femail .link-ccogr6 a, .femail .linkro-ccogr6 a:hover {
  color: #c562a5;
  background-color: #333; }

.health-ccox {
  color: #3cc; }

.health .wocc, .health .link-wocc a, .health .linkro-wocc a:hover {
  color: #fff;
  background-color: #3cc; }

.health .bocc, .health .link-bocc a, .health .linkro-bocc a:hover {
  color: #000;
  background-color: #3cc; }

.health .xocc, .health .link-xocc a, .health .linkro-xocc a:hover {
  background-color: #3cc; }

.health .xolcc, .health .link-xolcc a, .health .linkro-xolcc a:hover {
  background-color: #edffff; }

.health .lccox, .health .link-lccox a, .health .linkro-lccox a:hover {
  color: #edffff; }

.health .ccow, .health .link-ccow a, .health .linkro-ccow a:hover {
  color: #3cc;
  background-color: #fff; }

.health .ccob, .health .link-ccob a, .health .linkro-ccob a:hover {
  color: #3cc;
  background-color: #000; }

.health .ccox, .health .link-ccox a, .health .linkro-ccox a:hover {
  color: #3cc; }

.health .ccolcc, .health .link-ccolcc a, .health .linkro-ccolcc a:hover {
  color: #3cc;
  background-color: #edffff; }

.health .ccolcc, .health .link-ccolcc a, .health .linkro-ccolcc a:hover {
  color: #3cc;
  background-color: #e3e3e3; }

.health .ccogr1, .health .link-ccogr1 a, .health .linkro-ccogr1 a:hover {
  color: #3cc;
  background-color: #f5f5f5; }

.health .ccogr2, .health .link-ccogr2 a, .health .linkro-ccogr2 a:hover {
  color: #3cc;
  background-color: #e3e3e3; }

.health .ccogr3, .health .link-ccogr3 a, .health .linkro-ccogr3 a:hover {
  color: #3cc;
  background-color: #c0c0c0; }

.health .ccogr4, .health .link-ccogr4 a, .health .linkro-ccogr4 a:hover {
  color: #3cc;
  background-color: #999; }

.health .ccogr5, .health .link-ccogr5 a, .health .linkro-ccogr5 a:hover {
  color: #3cc;
  background-color: #666; }

.health .ccogr6, .health .link-ccogr6 a, .health .linkro-ccogr6 a:hover {
  color: #3cc;
  background-color: #333; }

.horoscopes-ccox {
  color: #083064; }

.horoscopes .wocc, .horoscopes .link-wocc a, .horoscopes .linkro-wocc a:hover {
  color: #fff;
  background-color: #083064; }

.horoscopes .bocc, .horoscopes .link-bocc a, .horoscopes .linkro-bocc a:hover {
  color: #000;
  background-color: #083064; }

.horoscopes .xocc, .horoscopes .link-xocc a, .horoscopes .linkro-xocc a:hover {
  background-color: #083064; }

.horoscopes .xolcc, .horoscopes .link-xolcc a, .horoscopes .linkro-xolcc a:hover {
  background-color: #e3edf8; }

.horoscopes .lccox, .horoscopes .link-lccox a, .horoscopes .linkro-lccox a:hover {
  color: #e3edf8; }

.horoscopes .ccow, .horoscopes .link-ccow a, .horoscopes .linkro-ccow a:hover {
  color: #083064;
  background-color: #fff; }

.horoscopes .ccob, .horoscopes .link-ccob a, .horoscopes .linkro-ccob a:hover {
  color: #083064;
  background-color: #000; }

.horoscopes .ccox, .horoscopes .link-ccox a, .horoscopes .linkro-ccox a:hover {
  color: #083064; }

.horoscopes .ccolcc, .horoscopes .link-ccolcc a, .horoscopes .linkro-ccolcc a:hover {
  color: #083064;
  background-color: #e3edf8; }

.horoscopes .ccolcc, .horoscopes .link-ccolcc a, .horoscopes .linkro-ccolcc a:hover {
  color: #083064;
  background-color: #e3e3e3; }

.horoscopes .ccogr1, .horoscopes .link-ccogr1 a, .horoscopes .linkro-ccogr1 a:hover {
  color: #083064;
  background-color: #f5f5f5; }

.horoscopes .ccogr2, .horoscopes .link-ccogr2 a, .horoscopes .linkro-ccogr2 a:hover {
  color: #083064;
  background-color: #e3e3e3; }

.horoscopes .ccogr3, .horoscopes .link-ccogr3 a, .horoscopes .linkro-ccogr3 a:hover {
  color: #083064;
  background-color: #c0c0c0; }

.horoscopes .ccogr4, .horoscopes .link-ccogr4 a, .horoscopes .linkro-ccogr4 a:hover {
  color: #083064;
  background-color: #999; }

.horoscopes .ccogr5, .horoscopes .link-ccogr5 a, .horoscopes .linkro-ccogr5 a:hover {
  color: #083064;
  background-color: #666; }

.horoscopes .ccogr6, .horoscopes .link-ccogr6 a, .horoscopes .linkro-ccogr6 a:hover {
  color: #083064;
  background-color: #333; }

.money-ccox {
  color: #451344; }

.money .wocc, .money .link-wocc a, .money .linkro-wocc a:hover {
  color: #fff;
  background-color: #451344; }

.money .bocc, .money .link-bocc a, .money .linkro-bocc a:hover {
  color: #000;
  background-color: #451344; }

.money .xocc, .money .link-xocc a, .money .linkro-xocc a:hover {
  background-color: #451344; }

.money .xolcc, .money .link-xolcc a, .money .linkro-xolcc a:hover {
  background-color: #f1e8f8; }

.money .lccox, .money .link-lccox a, .money .linkro-lccox a:hover {
  color: #f1e8f8; }

.money .ccow, .money .link-ccow a, .money .linkro-ccow a:hover {
  color: #451344;
  background-color: #fff; }

.money .ccob, .money .link-ccob a, .money .linkro-ccob a:hover {
  color: #451344;
  background-color: #000; }

.money .ccox, .money .link-ccox a, .money .linkro-ccox a:hover {
  color: #451344; }

.money .ccolcc, .money .link-ccolcc a, .money .linkro-ccolcc a:hover {
  color: #451344;
  background-color: #f1e8f8; }

.money .ccolcc, .money .link-ccolcc a, .money .linkro-ccolcc a:hover {
  color: #451344;
  background-color: #e3e3e3; }

.money .ccogr1, .money .link-ccogr1 a, .money .linkro-ccogr1 a:hover {
  color: #451344;
  background-color: #f5f5f5; }

.money .ccogr2, .money .link-ccogr2 a, .money .linkro-ccogr2 a:hover {
  color: #451344;
  background-color: #e3e3e3; }

.money .ccogr3, .money .link-ccogr3 a, .money .linkro-ccogr3 a:hover {
  color: #451344;
  background-color: #c0c0c0; }

.money .ccogr4, .money .link-ccogr4 a, .money .linkro-ccogr4 a:hover {
  color: #451344;
  background-color: #999; }

.money .ccogr5, .money .link-ccogr5 a, .money .linkro-ccogr5 a:hover {
  color: #451344;
  background-color: #666; }

.money .ccogr6, .money .link-ccogr6 a, .money .linkro-ccogr6 a:hover {
  color: #451344;
  background-color: #333; }

.moslive-ccox {
  color: #00b1a6; }

.moslive .wocc, .moslive .link-wocc a, .moslive .linkro-wocc a:hover {
  color: #fff;
  background-color: #00b1a6; }

.moslive .bocc, .moslive .link-bocc a, .moslive .linkro-bocc a:hover {
  color: #000;
  background-color: #00b1a6; }

.moslive .xocc, .moslive .link-xocc a, .moslive .linkro-xocc a:hover {
  background-color: #00b1a6; }

.moslive .xolcc, .moslive .link-xolcc a, .moslive .linkro-xolcc a:hover {
  background-color: #eee; }

.moslive .lccox, .moslive .link-lccox a, .moslive .linkro-lccox a:hover {
  color: #eee; }

.moslive .ccow, .moslive .link-ccow a, .moslive .linkro-ccow a:hover {
  color: #00b1a6;
  background-color: #fff; }

.moslive .ccob, .moslive .link-ccob a, .moslive .linkro-ccob a:hover {
  color: #00b1a6;
  background-color: #000; }

.moslive .ccox, .moslive .link-ccox a, .moslive .linkro-ccox a:hover {
  color: #00b1a6; }

.moslive .ccolcc, .moslive .link-ccolcc a, .moslive .linkro-ccolcc a:hover {
  color: #00b1a6;
  background-color: #eee; }

.moslive .ccolcc, .moslive .link-ccolcc a, .moslive .linkro-ccolcc a:hover {
  color: #00b1a6;
  background-color: #e3e3e3; }

.moslive .ccogr1, .moslive .link-ccogr1 a, .moslive .linkro-ccogr1 a:hover {
  color: #00b1a6;
  background-color: #f5f5f5; }

.moslive .ccogr2, .moslive .link-ccogr2 a, .moslive .linkro-ccogr2 a:hover {
  color: #00b1a6;
  background-color: #e3e3e3; }

.moslive .ccogr3, .moslive .link-ccogr3 a, .moslive .linkro-ccogr3 a:hover {
  color: #00b1a6;
  background-color: #c0c0c0; }

.moslive .ccogr4, .moslive .link-ccogr4 a, .moslive .linkro-ccogr4 a:hover {
  color: #00b1a6;
  background-color: #999; }

.moslive .ccogr5, .moslive .link-ccogr5 a, .moslive .linkro-ccogr5 a:hover {
  color: #00b1a6;
  background-color: #666; }

.moslive .ccogr6, .moslive .link-ccogr6 a, .moslive .linkro-ccogr6 a:hover {
  color: #00b1a6;
  background-color: #333; }

.motoring-ccox {
  color: #666; }

.motoring .wocc, .motoring .link-wocc a, .motoring .linkro-wocc a:hover {
  color: #fff;
  background-color: #666; }

.motoring .bocc, .motoring .link-bocc a, .motoring .linkro-bocc a:hover {
  color: #000;
  background-color: #666; }

.motoring .xocc, .motoring .link-xocc a, .motoring .linkro-xocc a:hover {
  background-color: #666; }

.motoring .xolcc, .motoring .link-xolcc a, .motoring .linkro-xolcc a:hover {
  background-color: #eee; }

.motoring .lccox, .motoring .link-lccox a, .motoring .linkro-lccox a:hover {
  color: #eee; }

.motoring .ccow, .motoring .link-ccow a, .motoring .linkro-ccow a:hover {
  color: #666;
  background-color: #fff; }

.motoring .ccob, .motoring .link-ccob a, .motoring .linkro-ccob a:hover {
  color: #666;
  background-color: #000; }

.motoring .ccox, .motoring .link-ccox a, .motoring .linkro-ccox a:hover {
  color: #666; }

.motoring .ccolcc, .motoring .link-ccolcc a, .motoring .linkro-ccolcc a:hover {
  color: #666;
  background-color: #eee; }

.motoring .ccolcc, .motoring .link-ccolcc a, .motoring .linkro-ccolcc a:hover {
  color: #666;
  background-color: #e3e3e3; }

.motoring .ccogr1, .motoring .link-ccogr1 a, .motoring .linkro-ccogr1 a:hover {
  color: #666;
  background-color: #f5f5f5; }

.motoring .ccogr2, .motoring .link-ccogr2 a, .motoring .linkro-ccogr2 a:hover {
  color: #666;
  background-color: #e3e3e3; }

.motoring .ccogr3, .motoring .link-ccogr3 a, .motoring .linkro-ccogr3 a:hover {
  color: #666;
  background-color: #c0c0c0; }

.motoring .ccogr4, .motoring .link-ccogr4 a, .motoring .linkro-ccogr4 a:hover {
  color: #666;
  background-color: #999; }

.motoring .ccogr5, .motoring .link-ccogr5 a, .motoring .linkro-ccogr5 a:hover {
  color: #666;
  background-color: #666; }

.motoring .ccogr6, .motoring .link-ccogr6 a, .motoring .linkro-ccogr6 a:hover {
  color: #666;
  background-color: #333; }

.news-ccox {
  color: #00aad2; }

.news .wocc, .news .link-wocc a, .news .linkro-wocc a:hover {
  color: #fff;
  background-color: #00aad2; }

.news .bocc, .news .link-bocc a, .news .linkro-bocc a:hover {
  color: #000;
  background-color: #00aad2; }

.news .xocc, .news .link-xocc a, .news .linkro-xocc a:hover {
  background-color: #00aad2; }

.news .xolcc, .news .link-xolcc a, .news .linkro-xolcc a:hover {
  background-color: #e8fbff; }

.news .lccox, .news .link-lccox a, .news .linkro-lccox a:hover {
  color: #e8fbff; }

.news .ccow, .news .link-ccow a, .news .linkro-ccow a:hover {
  color: #00aad2;
  background-color: #fff; }

.news .ccob, .news .link-ccob a, .news .linkro-ccob a:hover {
  color: #00aad2;
  background-color: #000; }

.news .ccox, .news .link-ccox a, .news .linkro-ccox a:hover {
  color: #00aad2; }

.news .ccolcc, .news .link-ccolcc a, .news .linkro-ccolcc a:hover {
  color: #00aad2;
  background-color: #e8fbff; }

.news .ccolcc, .news .link-ccolcc a, .news .linkro-ccolcc a:hover {
  color: #00aad2;
  background-color: #e3e3e3; }

.news .ccogr1, .news .link-ccogr1 a, .news .linkro-ccogr1 a:hover {
  color: #00aad2;
  background-color: #f5f5f5; }

.news .ccogr2, .news .link-ccogr2 a, .news .linkro-ccogr2 a:hover {
  color: #00aad2;
  background-color: #e3e3e3; }

.news .ccogr3, .news .link-ccogr3 a, .news .linkro-ccogr3 a:hover {
  color: #00aad2;
  background-color: #c0c0c0; }

.news .ccogr4, .news .link-ccogr4 a, .news .linkro-ccogr4 a:hover {
  color: #00aad2;
  background-color: #999; }

.news .ccogr5, .news .link-ccogr5 a, .news .linkro-ccogr5 a:hover {
  color: #00aad2;
  background-color: #666; }

.news .ccogr6, .news .link-ccogr6 a, .news .linkro-ccogr6 a:hover {
  color: #00aad2;
  background-color: #333; }

.nochannel-ccox {
  color: #000; }

.nochannel .wocc, .nochannel .link-wocc a, .nochannel .linkro-wocc a:hover {
  color: #fff;
  background-color: #000; }

.nochannel .bocc, .nochannel .link-bocc a, .nochannel .linkro-bocc a:hover {
  color: #000;
  background-color: #000; }

.nochannel .xocc, .nochannel .link-xocc a, .nochannel .linkro-xocc a:hover {
  background-color: #000; }

.nochannel .xolcc, .nochannel .link-xolcc a, .nochannel .linkro-xolcc a:hover {
  background-color: #eee; }

.nochannel .lccox, .nochannel .link-lccox a, .nochannel .linkro-lccox a:hover {
  color: #eee; }

.nochannel .ccow, .nochannel .link-ccow a, .nochannel .linkro-ccow a:hover {
  color: #000;
  background-color: #fff; }

.nochannel .ccob, .nochannel .link-ccob a, .nochannel .linkro-ccob a:hover {
  color: #000;
  background-color: #000; }

.nochannel .ccox, .nochannel .link-ccox a, .nochannel .linkro-ccox a:hover {
  color: #000; }

.nochannel .ccolcc, .nochannel .link-ccolcc a, .nochannel .linkro-ccolcc a:hover {
  color: #000;
  background-color: #eee; }

.nochannel .ccolcc, .nochannel .link-ccolcc a, .nochannel .linkro-ccolcc a:hover {
  color: #000;
  background-color: #e3e3e3; }

.nochannel .ccogr1, .nochannel .link-ccogr1 a, .nochannel .linkro-ccogr1 a:hover {
  color: #000;
  background-color: #f5f5f5; }

.nochannel .ccogr2, .nochannel .link-ccogr2 a, .nochannel .linkro-ccogr2 a:hover {
  color: #000;
  background-color: #e3e3e3; }

.nochannel .ccogr3, .nochannel .link-ccogr3 a, .nochannel .linkro-ccogr3 a:hover {
  color: #000;
  background-color: #c0c0c0; }

.nochannel .ccogr4, .nochannel .link-ccogr4 a, .nochannel .linkro-ccogr4 a:hover {
  color: #000;
  background-color: #999; }

.nochannel .ccogr5, .nochannel .link-ccogr5 a, .nochannel .linkro-ccogr5 a:hover {
  color: #000;
  background-color: #666; }

.nochannel .ccogr6, .nochannel .link-ccogr6 a, .nochannel .linkro-ccogr6 a:hover {
  color: #000;
  background-color: #333; }

.pictures-ccox {
  color: #f00; }

.pictures .wocc, .pictures .link-wocc a, .pictures .linkro-wocc a:hover {
  color: #fff;
  background-color: #f00; }

.pictures .bocc, .pictures .link-bocc a, .pictures .linkro-bocc a:hover {
  color: #000;
  background-color: #f00; }

.pictures .xocc, .pictures .link-xocc a, .pictures .linkro-xocc a:hover {
  background-color: #f00; }

.pictures .xolcc, .pictures .link-xolcc a, .pictures .linkro-xolcc a:hover {
  background-color: #fecece; }

.pictures .lccox, .pictures .link-lccox a, .pictures .linkro-lccox a:hover {
  color: #fecece; }

.pictures .ccow, .pictures .link-ccow a, .pictures .linkro-ccow a:hover {
  color: #f00;
  background-color: #fff; }

.pictures .ccob, .pictures .link-ccob a, .pictures .linkro-ccob a:hover {
  color: #f00;
  background-color: #000; }

.pictures .ccox, .pictures .link-ccox a, .pictures .linkro-ccox a:hover {
  color: #f00; }

.pictures .ccolcc, .pictures .link-ccolcc a, .pictures .linkro-ccolcc a:hover {
  color: #f00;
  background-color: #fecece; }

.pictures .ccolcc, .pictures .link-ccolcc a, .pictures .linkro-ccolcc a:hover {
  color: #f00;
  background-color: #e3e3e3; }

.pictures .ccogr1, .pictures .link-ccogr1 a, .pictures .linkro-ccogr1 a:hover {
  color: #f00;
  background-color: #f5f5f5; }

.pictures .ccogr2, .pictures .link-ccogr2 a, .pictures .linkro-ccogr2 a:hover {
  color: #f00;
  background-color: #e3e3e3; }

.pictures .ccogr3, .pictures .link-ccogr3 a, .pictures .linkro-ccogr3 a:hover {
  color: #f00;
  background-color: #c0c0c0; }

.pictures .ccogr4, .pictures .link-ccogr4 a, .pictures .linkro-ccogr4 a:hover {
  color: #f00;
  background-color: #999; }

.pictures .ccogr5, .pictures .link-ccogr5 a, .pictures .linkro-ccogr5 a:hover {
  color: #f00;
  background-color: #666; }

.pictures .ccogr6, .pictures .link-ccogr6 a, .pictures .linkro-ccogr6 a:hover {
  color: #f00;
  background-color: #333; }

.property-ccox {
  color: #ac46c6; }

.property .wocc, .property .link-wocc a, .property .linkro-wocc a:hover {
  color: #fff;
  background-color: #ac46c6; }

.property .bocc, .property .link-bocc a, .property .linkro-bocc a:hover {
  color: #000;
  background-color: #ac46c6; }

.property .xocc, .property .link-xocc a, .property .linkro-xocc a:hover {
  background-color: #ac46c6; }

.property .xolcc, .property .link-xolcc a, .property .linkro-xolcc a:hover {
  background-color: #f1e8f8; }

.property .lccox, .property .link-lccox a, .property .linkro-lccox a:hover {
  color: #f1e8f8; }

.property .ccow, .property .link-ccow a, .property .linkro-ccow a:hover {
  color: #ac46c6;
  background-color: #fff; }

.property .ccob, .property .link-ccob a, .property .linkro-ccob a:hover {
  color: #ac46c6;
  background-color: #000; }

.property .ccox, .property .link-ccox a, .property .linkro-ccox a:hover {
  color: #ac46c6; }

.property .ccolcc, .property .link-ccolcc a, .property .linkro-ccolcc a:hover {
  color: #ac46c6;
  background-color: #f1e8f8; }

.property .ccolcc, .property .link-ccolcc a, .property .linkro-ccolcc a:hover {
  color: #ac46c6;
  background-color: #e3e3e3; }

.property .ccogr1, .property .link-ccogr1 a, .property .linkro-ccogr1 a:hover {
  color: #ac46c6;
  background-color: #f5f5f5; }

.property .ccogr2, .property .link-ccogr2 a, .property .linkro-ccogr2 a:hover {
  color: #ac46c6;
  background-color: #e3e3e3; }

.property .ccogr3, .property .link-ccogr3 a, .property .linkro-ccogr3 a:hover {
  color: #ac46c6;
  background-color: #c0c0c0; }

.property .ccogr4, .property .link-ccogr4 a, .property .linkro-ccogr4 a:hover {
  color: #ac46c6;
  background-color: #999; }

.property .ccogr5, .property .link-ccogr5 a, .property .linkro-ccogr5 a:hover {
  color: #ac46c6;
  background-color: #666; }

.property .ccogr6, .property .link-ccogr6 a, .property .linkro-ccogr6 a:hover {
  color: #ac46c6;
  background-color: #333; }

.royal_wedding-ccox {
  color: #540c75; }

.royal_wedding .wocc, .royal_wedding .link-wocc a, .royal_wedding .linkro-wocc a:hover {
  color: #fff;
  background-color: #540c75; }

.royal_wedding .bocc, .royal_wedding .link-bocc a, .royal_wedding .linkro-bocc a:hover {
  color: #000;
  background-color: #540c75; }

.royal_wedding .xocc, .royal_wedding .link-xocc a, .royal_wedding .linkro-xocc a:hover {
  background-color: #540c75; }

.royal_wedding .xolcc, .royal_wedding .link-xolcc a, .royal_wedding .linkro-xolcc a:hover {
  background-color: #f1e8f8; }

.royal_wedding .lccox, .royal_wedding .link-lccox a, .royal_wedding .linkro-lccox a:hover {
  color: #f1e8f8; }

.royal_wedding .ccow, .royal_wedding .link-ccow a, .royal_wedding .linkro-ccow a:hover {
  color: #540c75;
  background-color: #fff; }

.royal_wedding .ccob, .royal_wedding .link-ccob a, .royal_wedding .linkro-ccob a:hover {
  color: #540c75;
  background-color: #000; }

.royal_wedding .ccox, .royal_wedding .link-ccox a, .royal_wedding .linkro-ccox a:hover {
  color: #540c75; }

.royal_wedding .ccolcc, .royal_wedding .link-ccolcc a, .royal_wedding .linkro-ccolcc a:hover {
  color: #540c75;
  background-color: #f1e8f8; }

.royal_wedding .ccolcc, .royal_wedding .link-ccolcc a, .royal_wedding .linkro-ccolcc a:hover {
  color: #540c75;
  background-color: #e3e3e3; }

.royal_wedding .ccogr1, .royal_wedding .link-ccogr1 a, .royal_wedding .linkro-ccogr1 a:hover {
  color: #540c75;
  background-color: #f5f5f5; }

.royal_wedding .ccogr2, .royal_wedding .link-ccogr2 a, .royal_wedding .linkro-ccogr2 a:hover {
  color: #540c75;
  background-color: #e3e3e3; }

.royal_wedding .ccogr3, .royal_wedding .link-ccogr3 a, .royal_wedding .linkro-ccogr3 a:hover {
  color: #540c75;
  background-color: #c0c0c0; }

.royal_wedding .ccogr4, .royal_wedding .link-ccogr4 a, .royal_wedding .linkro-ccogr4 a:hover {
  color: #540c75;
  background-color: #999; }

.royal_wedding .ccogr5, .royal_wedding .link-ccogr5 a, .royal_wedding .linkro-ccogr5 a:hover {
  color: #540c75;
  background-color: #666; }

.royal_wedding .ccogr6, .royal_wedding .link-ccogr6 a, .royal_wedding .linkro-ccogr6 a:hover {
  color: #540c75;
  background-color: #333; }

.sciencetech-ccox {
  color: #f60; }

.sciencetech .wocc, .sciencetech .link-wocc a, .sciencetech .linkro-wocc a:hover {
  color: #fff;
  background-color: #f60; }

.sciencetech .bocc, .sciencetech .link-bocc a, .sciencetech .linkro-bocc a:hover {
  color: #000;
  background-color: #f60; }

.sciencetech .xocc, .sciencetech .link-xocc a, .sciencetech .linkro-xocc a:hover {
  background-color: #f60; }

.sciencetech .xolcc, .sciencetech .link-xolcc a, .sciencetech .linkro-xolcc a:hover {
  background-color: #fff0e6; }

.sciencetech .lccox, .sciencetech .link-lccox a, .sciencetech .linkro-lccox a:hover {
  color: #fff0e6; }

.sciencetech .ccow, .sciencetech .link-ccow a, .sciencetech .linkro-ccow a:hover {
  color: #f60;
  background-color: #fff; }

.sciencetech .ccob, .sciencetech .link-ccob a, .sciencetech .linkro-ccob a:hover {
  color: #f60;
  background-color: #000; }

.sciencetech .ccox, .sciencetech .link-ccox a, .sciencetech .linkro-ccox a:hover {
  color: #f60; }

.sciencetech .ccolcc, .sciencetech .link-ccolcc a, .sciencetech .linkro-ccolcc a:hover {
  color: #f60;
  background-color: #fff0e6; }

.sciencetech .ccolcc, .sciencetech .link-ccolcc a, .sciencetech .linkro-ccolcc a:hover {
  color: #f60;
  background-color: #e3e3e3; }

.sciencetech .ccogr1, .sciencetech .link-ccogr1 a, .sciencetech .linkro-ccogr1 a:hover {
  color: #f60;
  background-color: #f5f5f5; }

.sciencetech .ccogr2, .sciencetech .link-ccogr2 a, .sciencetech .linkro-ccogr2 a:hover {
  color: #f60;
  background-color: #e3e3e3; }

.sciencetech .ccogr3, .sciencetech .link-ccogr3 a, .sciencetech .linkro-ccogr3 a:hover {
  color: #f60;
  background-color: #c0c0c0; }

.sciencetech .ccogr4, .sciencetech .link-ccogr4 a, .sciencetech .linkro-ccogr4 a:hover {
  color: #f60;
  background-color: #999; }

.sciencetech .ccogr5, .sciencetech .link-ccogr5 a, .sciencetech .linkro-ccogr5 a:hover {
  color: #f60;
  background-color: #666; }

.sciencetech .ccogr6, .sciencetech .link-ccogr6 a, .sciencetech .linkro-ccogr6 a:hover {
  color: #f60;
  background-color: #333; }

.sport-ccox {
  color: #0cac0c; }

.sport .wocc, .sport .link-wocc a, .sport .linkro-wocc a:hover {
  color: #fff;
  background-color: #0cac0c; }

.sport .bocc, .sport .link-bocc a, .sport .linkro-bocc a:hover {
  color: #000;
  background-color: #0cac0c; }

.sport .xocc, .sport .link-xocc a, .sport .linkro-xocc a:hover {
  background-color: #0cac0c; }

.sport .xolcc, .sport .link-xolcc a, .sport .linkro-xolcc a:hover {
  background-color: #e8ffe8; }

.sport .lccox, .sport .link-lccox a, .sport .linkro-lccox a:hover {
  color: #e8ffe8; }

.sport .ccow, .sport .link-ccow a, .sport .linkro-ccow a:hover {
  color: #0cac0c;
  background-color: #fff; }

.sport .ccob, .sport .link-ccob a, .sport .linkro-ccob a:hover {
  color: #0cac0c;
  background-color: #000; }

.sport .ccox, .sport .link-ccox a, .sport .linkro-ccox a:hover {
  color: #0cac0c; }

.sport .ccolcc, .sport .link-ccolcc a, .sport .linkro-ccolcc a:hover {
  color: #0cac0c;
  background-color: #e8ffe8; }

.sport .ccolcc, .sport .link-ccolcc a, .sport .linkro-ccolcc a:hover {
  color: #0cac0c;
  background-color: #e3e3e3; }

.sport .ccogr1, .sport .link-ccogr1 a, .sport .linkro-ccogr1 a:hover {
  color: #0cac0c;
  background-color: #f5f5f5; }

.sport .ccogr2, .sport .link-ccogr2 a, .sport .linkro-ccogr2 a:hover {
  color: #0cac0c;
  background-color: #e3e3e3; }

.sport .ccogr3, .sport .link-ccogr3 a, .sport .linkro-ccogr3 a:hover {
  color: #0cac0c;
  background-color: #c0c0c0; }

.sport .ccogr4, .sport .link-ccogr4 a, .sport .linkro-ccogr4 a:hover {
  color: #0cac0c;
  background-color: #999; }

.sport .ccogr5, .sport .link-ccogr5 a, .sport .linkro-ccogr5 a:hover {
  color: #0cac0c;
  background-color: #666; }

.sport .ccogr6, .sport .link-ccogr6 a, .sport .linkro-ccogr6 a:hover {
  color: #0cac0c;
  background-color: #333; }

.strayer-ccox {
  color: #b92025; }

.strayer .wocc, .strayer .link-wocc a, .strayer .linkro-wocc a:hover {
  color: #fff;
  background-color: #b92025; }

.strayer .bocc, .strayer .link-bocc a, .strayer .linkro-bocc a:hover {
  color: #000;
  background-color: #b92025; }

.strayer .xocc, .strayer .link-xocc a, .strayer .linkro-xocc a:hover {
  background-color: #b92025; }

.strayer .xolcc, .strayer .link-xolcc a, .strayer .linkro-xolcc a:hover {
  background-color: #ebbcbd; }

.strayer .lccox, .strayer .link-lccox a, .strayer .linkro-lccox a:hover {
  color: #ebbcbd; }

.strayer .ccow, .strayer .link-ccow a, .strayer .linkro-ccow a:hover {
  color: #b92025;
  background-color: #fff; }

.strayer .ccob, .strayer .link-ccob a, .strayer .linkro-ccob a:hover {
  color: #b92025;
  background-color: #000; }

.strayer .ccox, .strayer .link-ccox a, .strayer .linkro-ccox a:hover {
  color: #b92025; }

.strayer .ccolcc, .strayer .link-ccolcc a, .strayer .linkro-ccolcc a:hover {
  color: #b92025;
  background-color: #ebbcbd; }

.strayer .ccolcc, .strayer .link-ccolcc a, .strayer .linkro-ccolcc a:hover {
  color: #b92025;
  background-color: #e3e3e3; }

.strayer .ccogr1, .strayer .link-ccogr1 a, .strayer .linkro-ccogr1 a:hover {
  color: #b92025;
  background-color: #f5f5f5; }

.strayer .ccogr2, .strayer .link-ccogr2 a, .strayer .linkro-ccogr2 a:hover {
  color: #b92025;
  background-color: #e3e3e3; }

.strayer .ccogr3, .strayer .link-ccogr3 a, .strayer .linkro-ccogr3 a:hover {
  color: #b92025;
  background-color: #c0c0c0; }

.strayer .ccogr4, .strayer .link-ccogr4 a, .strayer .linkro-ccogr4 a:hover {
  color: #b92025;
  background-color: #999; }

.strayer .ccogr5, .strayer .link-ccogr5 a, .strayer .linkro-ccogr5 a:hover {
  color: #b92025;
  background-color: #666; }

.strayer .ccogr6, .strayer .link-ccogr6 a, .strayer .linkro-ccogr6 a:hover {
  color: #b92025;
  background-color: #333; }

.travel-ccox {
  color: #082340; }

.travel .wocc, .travel .link-wocc a, .travel .linkro-wocc a:hover {
  color: #fff;
  background-color: #082340; }

.travel .bocc, .travel .link-bocc a, .travel .linkro-bocc a:hover {
  color: #000;
  background-color: #082340; }

.travel .xocc, .travel .link-xocc a, .travel .linkro-xocc a:hover {
  background-color: #082340; }

.travel .xolcc, .travel .link-xolcc a, .travel .linkro-xolcc a:hover {
  background-color: #e3edf8; }

.travel .lccox, .travel .link-lccox a, .travel .linkro-lccox a:hover {
  color: #e3edf8; }

.travel .ccow, .travel .link-ccow a, .travel .linkro-ccow a:hover {
  color: #082340;
  background-color: #fff; }

.travel .ccob, .travel .link-ccob a, .travel .linkro-ccob a:hover {
  color: #082340;
  background-color: #000; }

.travel .ccox, .travel .link-ccox a, .travel .linkro-ccox a:hover {
  color: #082340; }

.travel .ccolcc, .travel .link-ccolcc a, .travel .linkro-ccolcc a:hover {
  color: #082340;
  background-color: #e3edf8; }

.travel .ccolcc, .travel .link-ccolcc a, .travel .linkro-ccolcc a:hover {
  color: #082340;
  background-color: #e3e3e3; }

.travel .ccogr1, .travel .link-ccogr1 a, .travel .linkro-ccogr1 a:hover {
  color: #082340;
  background-color: #f5f5f5; }

.travel .ccogr2, .travel .link-ccogr2 a, .travel .linkro-ccogr2 a:hover {
  color: #082340;
  background-color: #e3e3e3; }

.travel .ccogr3, .travel .link-ccogr3 a, .travel .linkro-ccogr3 a:hover {
  color: #082340;
  background-color: #c0c0c0; }

.travel .ccogr4, .travel .link-ccogr4 a, .travel .linkro-ccogr4 a:hover {
  color: #082340;
  background-color: #999; }

.travel .ccogr5, .travel .link-ccogr5 a, .travel .linkro-ccogr5 a:hover {
  color: #082340;
  background-color: #666; }

.travel .ccogr6, .travel .link-ccogr6 a, .travel .linkro-ccogr6 a:hover {
  color: #082340;
  background-color: #333; }

.tvshowbiz-ccox {
  color: #e01c44; }

.tvshowbiz .wocc, .tvshowbiz .link-wocc a, .tvshowbiz .linkro-wocc a:hover {
  color: #fff;
  background-color: #e01c44; }

.tvshowbiz .bocc, .tvshowbiz .link-bocc a, .tvshowbiz .linkro-bocc a:hover {
  color: #000;
  background-color: #e01c44; }

.tvshowbiz .xocc, .tvshowbiz .link-xocc a, .tvshowbiz .linkro-xocc a:hover {
  background-color: #e01c44; }

.tvshowbiz .xolcc, .tvshowbiz .link-xolcc a, .tvshowbiz .linkro-xolcc a:hover {
  background-color: #ffe8ed; }

.tvshowbiz .lccox, .tvshowbiz .link-lccox a, .tvshowbiz .linkro-lccox a:hover {
  color: #ffe8ed; }

.tvshowbiz .ccow, .tvshowbiz .link-ccow a, .tvshowbiz .linkro-ccow a:hover {
  color: #e01c44;
  background-color: #fff; }

.tvshowbiz .ccob, .tvshowbiz .link-ccob a, .tvshowbiz .linkro-ccob a:hover {
  color: #e01c44;
  background-color: #000; }

.tvshowbiz .ccox, .tvshowbiz .link-ccox a, .tvshowbiz .linkro-ccox a:hover {
  color: #e01c44; }

.tvshowbiz .ccolcc, .tvshowbiz .link-ccolcc a, .tvshowbiz .linkro-ccolcc a:hover {
  color: #e01c44;
  background-color: #ffe8ed; }

.tvshowbiz .ccolcc, .tvshowbiz .link-ccolcc a, .tvshowbiz .linkro-ccolcc a:hover {
  color: #e01c44;
  background-color: #e3e3e3; }

.tvshowbiz .ccogr1, .tvshowbiz .link-ccogr1 a, .tvshowbiz .linkro-ccogr1 a:hover {
  color: #e01c44;
  background-color: #f5f5f5; }

.tvshowbiz .ccogr2, .tvshowbiz .link-ccogr2 a, .tvshowbiz .linkro-ccogr2 a:hover {
  color: #e01c44;
  background-color: #e3e3e3; }

.tvshowbiz .ccogr3, .tvshowbiz .link-ccogr3 a, .tvshowbiz .linkro-ccogr3 a:hover {
  color: #e01c44;
  background-color: #c0c0c0; }

.tvshowbiz .ccogr4, .tvshowbiz .link-ccogr4 a, .tvshowbiz .linkro-ccogr4 a:hover {
  color: #e01c44;
  background-color: #999; }

.tvshowbiz .ccogr5, .tvshowbiz .link-ccogr5 a, .tvshowbiz .linkro-ccogr5 a:hover {
  color: #e01c44;
  background-color: #666; }

.tvshowbiz .ccogr6, .tvshowbiz .link-ccogr6 a, .tvshowbiz .linkro-ccogr6 a:hover {
  color: #e01c44;
  background-color: #333; }

.video-ccox {
  color: #004db4; }

.video .wocc, .video .link-wocc a, .video .linkro-wocc a:hover {
  color: #fff;
  background-color: #004db4; }

.video .bocc, .video .link-bocc a, .video .linkro-bocc a:hover {
  color: #000;
  background-color: #004db4; }

.video .xocc, .video .link-xocc a, .video .linkro-xocc a:hover {
  background-color: #004db4; }

.video .xolcc, .video .link-xolcc a, .video .linkro-xolcc a:hover {
  background-color: #e8f3ff; }

.video .lccox, .video .link-lccox a, .video .linkro-lccox a:hover {
  color: #e8f3ff; }

.video .ccow, .video .link-ccow a, .video .linkro-ccow a:hover {
  color: #004db4;
  background-color: #fff; }

.video .ccob, .video .link-ccob a, .video .linkro-ccob a:hover {
  color: #004db4;
  background-color: #000; }

.video .ccox, .video .link-ccox a, .video .linkro-ccox a:hover {
  color: #004db4; }

.video .ccolcc, .video .link-ccolcc a, .video .linkro-ccolcc a:hover {
  color: #004db4;
  background-color: #e8f3ff; }

.video .ccolcc, .video .link-ccolcc a, .video .linkro-ccolcc a:hover {
  color: #004db4;
  background-color: #e3e3e3; }

.video .ccogr1, .video .link-ccogr1 a, .video .linkro-ccogr1 a:hover {
  color: #004db4;
  background-color: #f5f5f5; }

.video .ccogr2, .video .link-ccogr2 a, .video .linkro-ccogr2 a:hover {
  color: #004db4;
  background-color: #e3e3e3; }

.video .ccogr3, .video .link-ccogr3 a, .video .linkro-ccogr3 a:hover {
  color: #004db4;
  background-color: #c0c0c0; }

.video .ccogr4, .video .link-ccogr4 a, .video .linkro-ccogr4 a:hover {
  color: #004db4;
  background-color: #999; }

.video .ccogr5, .video .link-ccogr5 a, .video .linkro-ccogr5 a:hover {
  color: #004db4;
  background-color: #666; }

.video .ccogr6, .video .link-ccogr6 a, .video .linkro-ccogr6 a:hover {
  color: #004db4;
  background-color: #333; }

.you-ccox {
  color: #d42699; }

.you .wocc, .you .link-wocc a, .you .linkro-wocc a:hover {
  color: #fff;
  background-color: #d42699; }

.you .bocc, .you .link-bocc a, .you .linkro-bocc a:hover {
  color: #000;
  background-color: #d42699; }

.you .xocc, .you .link-xocc a, .you .linkro-xocc a:hover {
  background-color: #d42699; }

.you .xolcc, .you .link-xolcc a, .you .linkro-xolcc a:hover {
  background-color: #ffe8f7; }

.you .lccox, .you .link-lccox a, .you .linkro-lccox a:hover {
  color: #ffe8f7; }

.you .ccow, .you .link-ccow a, .you .linkro-ccow a:hover {
  color: #d42699;
  background-color: #fff; }

.you .ccob, .you .link-ccob a, .you .linkro-ccob a:hover {
  color: #d42699;
  background-color: #000; }

.you .ccox, .you .link-ccox a, .you .linkro-ccox a:hover {
  color: #d42699; }

.you .ccolcc, .you .link-ccolcc a, .you .linkro-ccolcc a:hover {
  color: #d42699;
  background-color: #ffe8f7; }

.you .ccolcc, .you .link-ccolcc a, .you .linkro-ccolcc a:hover {
  color: #d42699;
  background-color: #e3e3e3; }

.you .ccogr1, .you .link-ccogr1 a, .you .linkro-ccogr1 a:hover {
  color: #d42699;
  background-color: #f5f5f5; }

.you .ccogr2, .you .link-ccogr2 a, .you .linkro-ccogr2 a:hover {
  color: #d42699;
  background-color: #e3e3e3; }

.you .ccogr3, .you .link-ccogr3 a, .you .linkro-ccogr3 a:hover {
  color: #d42699;
  background-color: #c0c0c0; }

.you .ccogr4, .you .link-ccogr4 a, .you .linkro-ccogr4 a:hover {
  color: #d42699;
  background-color: #999; }

.you .ccogr5, .you .link-ccogr5 a, .you .linkro-ccogr5 a:hover {
  color: #d42699;
  background-color: #666; }

.you .ccogr6, .you .link-ccogr6 a, .you .linkro-ccogr6 a:hover {
  color: #d42699;
  background-color: #333; }

.metro-ccox {
  color: #ffc000; }

.metro .wocc, .metro .link-wocc a, .metro .linkro-wocc a:hover {
  color: #fff;
  background-color: #ffc000; }

.metro .bocc, .metro .link-bocc a, .metro .linkro-bocc a:hover {
  color: #000;
  background-color: #ffc000; }

.metro .xocc, .metro .link-xocc a, .metro .linkro-xocc a:hover {
  background-color: #ffc000; }

.metro .xolcc, .metro .link-xolcc a, .metro .linkro-xolcc a:hover {
  background-color: #fff7de; }

.metro .lccox, .metro .link-lccox a, .metro .linkro-lccox a:hover {
  color: #fff7de; }

.metro .ccow, .metro .link-ccow a, .metro .linkro-ccow a:hover {
  color: #ffc000;
  background-color: #fff; }

.metro .ccob, .metro .link-ccob a, .metro .linkro-ccob a:hover {
  color: #ffc000;
  background-color: #000; }

.metro .ccox, .metro .link-ccox a, .metro .linkro-ccox a:hover {
  color: #ffc000; }

.metro .ccolcc, .metro .link-ccolcc a, .metro .linkro-ccolcc a:hover {
  color: #ffc000;
  background-color: #fff7de; }

.metro .ccolcc, .metro .link-ccolcc a, .metro .linkro-ccolcc a:hover {
  color: #ffc000;
  background-color: #e3e3e3; }

.metro .ccogr1, .metro .link-ccogr1 a, .metro .linkro-ccogr1 a:hover {
  color: #ffc000;
  background-color: #f5f5f5; }

.metro .ccogr2, .metro .link-ccogr2 a, .metro .linkro-ccogr2 a:hover {
  color: #ffc000;
  background-color: #e3e3e3; }

.metro .ccogr3, .metro .link-ccogr3 a, .metro .linkro-ccogr3 a:hover {
  color: #ffc000;
  background-color: #c0c0c0; }

.metro .ccogr4, .metro .link-ccogr4 a, .metro .linkro-ccogr4 a:hover {
  color: #ffc000;
  background-color: #999; }

.metro .ccogr5, .metro .link-ccogr5 a, .metro .linkro-ccogr5 a:hover {
  color: #ffc000;
  background-color: #666; }

.metro .ccogr6, .metro .link-ccogr6 a, .metro .linkro-ccogr6 a:hover {
  color: #ffc000;
  background-color: #333; }

.home-ccox {
  color: #004db3; }

.home .wocc, .home .link-wocc a, .home .linkro-wocc a:hover {
  color: #fff;
  background-color: #004db3; }

.home .bocc, .home .link-bocc a, .home .linkro-bocc a:hover {
  color: #000;
  background-color: #004db3; }

.home .xocc, .home .link-xocc a, .home .linkro-xocc a:hover {
  background-color: #004db3; }

.home .xolcc, .home .link-xolcc a, .home .linkro-xolcc a:hover {
  background-color: #e8f3ff; }

.home .lccox, .home .link-lccox a, .home .linkro-lccox a:hover {
  color: #e8f3ff; }

.home .ccow, .home .link-ccow a, .home .linkro-ccow a:hover {
  color: #004db3;
  background-color: #fff; }

.home .ccob, .home .link-ccob a, .home .linkro-ccob a:hover {
  color: #004db3;
  background-color: #000; }

.home .ccox, .home .link-ccox a, .home .linkro-ccox a:hover {
  color: #004db3; }

.home .ccolcc, .home .link-ccolcc a, .home .linkro-ccolcc a:hover {
  color: #004db3;
  background-color: #e8f3ff; }

.home .ccolcc, .home .link-ccolcc a, .home .linkro-ccolcc a:hover {
  color: #004db3;
  background-color: #e3e3e3; }

.home .ccogr1, .home .link-ccogr1 a, .home .linkro-ccogr1 a:hover {
  color: #004db3;
  background-color: #f5f5f5; }

.home .ccogr2, .home .link-ccogr2 a, .home .linkro-ccogr2 a:hover {
  color: #004db3;
  background-color: #e3e3e3; }

.home .ccogr3, .home .link-ccogr3 a, .home .linkro-ccogr3 a:hover {
  color: #004db3;
  background-color: #c0c0c0; }

.home .ccogr4, .home .link-ccogr4 a, .home .linkro-ccogr4 a:hover {
  color: #004db3;
  background-color: #999; }

.home .ccogr5, .home .link-ccogr5 a, .home .linkro-ccogr5 a:hover {
  color: #004db3;
  background-color: #666; }

.home .ccogr6, .home .link-ccogr6 a, .home .linkro-ccogr6 a:hover {
  color: #004db3;
  background-color: #333; }

.box, .link-box a, .linkro-box a:hover {
  color: #000; }

.xob, .link-xob a, .linkro-xob a:hover {
  background-color: #000; }

.bogr1, .link-bogr1 a, .linkro-bogr1 a:hover {
  color: #000;
  background-color: #f5f5f5; }

.bogr2, .link-bogr2 a, .linkro-bogr2 a:hover {
  color: #000;
  background-color: #e3e3e3; }

.bogr3, .link-bogr3 a, .linkro-bogr3 a:hover {
  color: #000;
  background-color: #c0c0c0; }

.bogr4, .link-bogr4 a, .linkro-bogr4 a:hover {
  color: #000;
  background-color: #999; }

.bogr5, .link-bogr5 a, .linkro-bogr5 a:hover {
  color: #000;
  background-color: #666; }

.bogr6, .link-bogr6 a, .linkro-bogr6 a:hover {
  color: #000;
  background-color: #333; }

.wox, .link-wox a, .linkro-wox a:hover {
  color: #fff; }

.xow, .link-xow a, .linkro-xow a:hover {
  background-color: #fff; }

.wogr1, .link-wogr1 a, .linkro-wogr1 a:hover {
  color: #fff;
  background-color: #f5f5f5; }

.wogr2, .link-wogr2 a, .linkro-wogr2 a:hover {
  color: #fff;
  background-color: #e3e3e3; }

.wogr3, .link-wogr3 a, .linkro-wogr3 a:hover {
  color: #fff;
  background-color: #c0c0c0; }

.wogr4, .link-wogr4 a, .linkro-wogr4 a:hover {
  color: #fff;
  background-color: #999; }

.wogr5, .link-wogr5 a, .linkro-wogr5 a:hover {
  color: #fff;
  background-color: #666; }

.wogr6, .link-wogr6 a, .linkro-wogr6 a:hover {
  color: #fff;
  background-color: #333; }

.bow, .link-bow a, .linkro-bow a:hover {
  color: #000;
  background-color: #fff; }

.wob, .link-wob a, .linkro-wob a:hover {
  color: #fff;
  background-color: #000; }

.gr1ox, .link-gr1ox a, .linkro-gr1ox a:hover {
  color: #f5f5f5; }

.xogr1, .link-xogr1 a, .linkro-xogr1 a:hover {
  background-color: #f5f5f5; }

.gr2ox, .link-gr2ox a, .linkro-gr2ox a:hover {
  color: #e3e3e3; }

.xogr2, .link-xogr2 a, .linkro-xogr2 a:hover {
  background-color: #e3e3e3; }

.gr3ox, .link-gr3ox a, .linkro-gr3ox a:hover {
  color: #c0c0c0; }

.xogr3, .link-xogr3 a, .linkro-xogr3 a:hover {
  background-color: #c0c0c0; }

.gr4ox, .link-gr4ox a, .linkro-gr4ox a:hover {
  color: #999; }

.xogr4, .link-xogr4 a, .linkro-xogr4 a:hover {
  background-color: #999; }

.gr5ox, .link-gr5ox a, .linkro-gr5ox a:hover {
  color: #666; }

.xogr5, .link-xogr5 a, .linkro-xogr5 a:hover {
  background-color: #666; }

.gr6ox, .link-gr6ox a, .linkro-gr6ox a:hover {
  color: #333; }

.xogr6, .link-xogr6 a, .linkro-xogr6 a:hover {
  background-color: #333; }

.homeblue, .link-homeblue a, .linkro-homeblue a:hover {
  color: #004db3; }

.linkro-grey a:hover {
  color: #003580; }

.linkro-darkred a:hover {
  color: #c00; }

/**
 * Coloured borders
 *
 * KEYS
 * bdr = border
 * w = white
 * b = black
 * x = transparent
 * cc = channnel colour
 * grN = grey colour
 *
 * i.e.
 * .bdrgr1 - gr1 colour border
 *
 */
.coffeebreak .bdrcc,
.coffeebreak a.link-bdrcc,
.coffeebreak .linkro-bdrcc:hover,
.coffeebreak a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #823d1e; }

.dailymailtv .bdrcc,
.dailymailtv a.link-bdrcc,
.dailymailtv .linkro-bdrcc:hover,
.dailymailtv a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #004db3; }

.debate .bdrcc,
.debate a.link-bdrcc,
.debate .linkro-bdrcc:hover,
.debate a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #a31414; }

.event .bdrcc,
.event a.link-bdrcc,
.event .linkro-bdrcc:hover,
.event a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #00b1a6; }

.femail .bdrcc,
.femail a.link-bdrcc,
.femail .linkro-bdrcc:hover,
.femail a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #c562a5; }

.health .bdrcc,
.health a.link-bdrcc,
.health .linkro-bdrcc:hover,
.health a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #3cc; }

.horoscopes .bdrcc,
.horoscopes a.link-bdrcc,
.horoscopes .linkro-bdrcc:hover,
.horoscopes a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #083064; }

.money .bdrcc,
.money a.link-bdrcc,
.money .linkro-bdrcc:hover,
.money a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #451344; }

.moslive .bdrcc,
.moslive a.link-bdrcc,
.moslive .linkro-bdrcc:hover,
.moslive a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #00b1a6; }

.motoring .bdrcc,
.motoring a.link-bdrcc,
.motoring .linkro-bdrcc:hover,
.motoring a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #666; }

.news .bdrcc,
.news a.link-bdrcc,
.news .linkro-bdrcc:hover,
.news a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #00aad2; }

.nochannel .bdrcc,
.nochannel a.link-bdrcc,
.nochannel .linkro-bdrcc:hover,
.nochannel a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #000; }

.pictures .bdrcc,
.pictures a.link-bdrcc,
.pictures .linkro-bdrcc:hover,
.pictures a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #f00; }

.property .bdrcc,
.property a.link-bdrcc,
.property .linkro-bdrcc:hover,
.property a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #ac46c6; }

.royal_wedding .bdrcc,
.royal_wedding a.link-bdrcc,
.royal_wedding .linkro-bdrcc:hover,
.royal_wedding a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #540c75; }

.sciencetech .bdrcc,
.sciencetech a.link-bdrcc,
.sciencetech .linkro-bdrcc:hover,
.sciencetech a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #f60; }

.sport .bdrcc,
.sport a.link-bdrcc,
.sport .linkro-bdrcc:hover,
.sport a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #0cac0c; }

.strayer .bdrcc,
.strayer a.link-bdrcc,
.strayer .linkro-bdrcc:hover,
.strayer a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #b92025; }

.travel .bdrcc,
.travel a.link-bdrcc,
.travel .linkro-bdrcc:hover,
.travel a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #082340; }

.tvshowbiz .bdrcc,
.tvshowbiz a.link-bdrcc,
.tvshowbiz .linkro-bdrcc:hover,
.tvshowbiz a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #e01c44; }

.video .bdrcc,
.video a.link-bdrcc,
.video .linkro-bdrcc:hover,
.video a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #004db4; }

.you .bdrcc,
.you a.link-bdrcc,
.you .linkro-bdrcc:hover,
.you a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #d42699; }

.metro .bdrcc,
.metro a.link-bdrcc,
.metro .linkro-bdrcc:hover,
.metro a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #ffc000; }

.home .bdrcc,
.home a.link-bdrcc,
.home .linkro-bdrcc:hover,
.home a:hover .linkro-bdrcc {
  border-width: 1px;
  border-style: solid;
  border-color: #004db3; }

.bdrgr1,
a.link-bdrgr1,
a.linkro-bdrgr1:hover,
a:hover .linkro-bdrgr1 {
  border-width: 1px;
  border-style: solid;
  border-color: #f5f5f5; }

.bdrgr2,
a.link-bdrgr2,
a.linkro-bdrgr2:hover,
a:hover .linkro-bdrgr2 {
  border-width: 1px;
  border-style: solid;
  border-color: #e3e3e3; }

.bdrgr3,
a.link-bdrgr3,
a.linkro-bdrgr3:hover,
a:hover .linkro-bdrgr3 {
  border-width: 1px;
  border-style: solid;
  border-color: #c0c0c0; }

.bdrgr4,
a.link-bdrgr4,
a.linkro-bdrgr4:hover,
a:hover .linkro-bdrgr4 {
  border-width: 1px;
  border-style: solid;
  border-color: #999; }

.bdrgr5,
a.link-bdrgr5,
a.linkro-bdrgr5:hover,
a:hover .linkro-bdrgr5 {
  border-width: 1px;
  border-style: solid;
  border-color: #666; }

.bdrgr6,
a.link-bdrgr6,
a.linkro-bdrgr6:hover,
a:hover .linkro-bdrgr6 {
  border-width: 1px;
  border-style: solid;
  border-color: #333; }

.bdrb,
a.link-bdrb,
a.linkro-bdrb:hover,
a:hover .linkro-bdrb {
  border-width: 1px;
  border-style: solid;
  border-color: #000; }

.bdrw,
a.link-bdrw,
a.linkro-bdrw:hover,
a:hover .linkro-bdrw {
  border-width: 1px;
  border-style: solid;
  border-color: #fff; }

/*
  Triangle helper mixin (https://css-tricks.com/snippets/sass/css-triangle-mixin/)

   @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
   @param {Color} $color [currentcolor] - Triangle color
   @param {Length} $size [1em] - Triangle size
*/
.arrow-small-r,
.arrow-small-u,
.arrow-small-l,
.arrow-small-d,
.arrow-large-r,
.arrow-large-u,
.arrow-large-l,
.arrow-large-d {
  float: left; }

.arrow-small-d {
  display: inline-block;
  height: 0;
  font-size: 1em;
  line-height: 1em;
  vertical-align: middle;
  box-sizing: border-box;
  border: 0;
  border-top: 5px solid currentcolor;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent; }

.arrow-small-u {
  display: inline-block;
  height: 0;
  font-size: 1em;
  line-height: 1em;
  vertical-align: middle;
  box-sizing: border-box;
  border: 0;
  border-bottom: 5px solid currentcolor;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent; }

.arrow-small-r {
  display: inline-block;
  height: 0;
  font-size: 1em;
  line-height: 1em;
  vertical-align: middle;
  box-sizing: border-box;
  border: 0;
  border-left: 5px solid currentcolor;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent; }

.arrow-small-l {
  display: inline-block;
  height: 0;
  font-size: 1em;
  line-height: 1em;
  vertical-align: middle;
  box-sizing: border-box;
  border: 0;
  border-right: 5px solid currentcolor;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent; }

.arrow-large-d {
  display: inline-block;
  height: 0;
  font-size: 1em;
  line-height: 1em;
  vertical-align: middle;
  box-sizing: border-box;
  border: 0;
  border-top: 7px solid currentcolor;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent; }

.arrow-large-u {
  display: inline-block;
  height: 0;
  font-size: 1em;
  line-height: 1em;
  vertical-align: middle;
  box-sizing: border-box;
  border: 0;
  border-bottom: 7px solid currentcolor;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent; }

.arrow-large-r {
  display: inline-block;
  height: 0;
  font-size: 1em;
  line-height: 1em;
  vertical-align: middle;
  box-sizing: border-box;
  border: 0;
  border-left: 7px solid currentcolor;
  border-bottom: 7px solid transparent;
  border-top: 7px solid transparent; }

.arrow-large-l {
  display: inline-block;
  height: 0;
  font-size: 1em;
  line-height: 1em;
  vertical-align: middle;
  box-sizing: border-box;
  border: 0;
  border-right: 7px solid currentcolor;
  border-bottom: 7px solid transparent;
  border-top: 7px solid transparent; }

/*
  Returns the styles for a slant box

  @param {string} $postion - Slant position, either `top` or `bottom`
*/
/**
 * Classes for image_strapline_slunt_* modules
 */
.slant-small-t {
  display: block;
  overflow: hidden;
  height: 8px;
  width: 292px; }
  .slant-small-t::before {
    content: '';
    height: 100%;
    width: 100%;
    display: block;
    transform: skewY(-1.5deg);
    transform-origin: 100%; }

.slant-small-b {
  display: block;
  overflow: hidden;
  height: 8px;
  width: 292px; }
  .slant-small-b::before {
    content: '';
    height: 100%;
    width: 100%;
    display: block;
    transform: skewY(-1.5deg);
    transform-origin: 0%; }

.coffeebreak .slant-small-t::before,
.coffeebreak .slant-small-b::before {
  background-color: #823d1e; }

.dailymailtv .slant-small-t::before,
.dailymailtv .slant-small-b::before {
  background-color: #004db3; }

.debate .slant-small-t::before,
.debate .slant-small-b::before {
  background-color: #a31414; }

.event .slant-small-t::before,
.event .slant-small-b::before {
  background-color: #00b1a6; }

.femail .slant-small-t::before,
.femail .slant-small-b::before {
  background-color: #c562a5; }

.health .slant-small-t::before,
.health .slant-small-b::before {
  background-color: #3cc; }

.horoscopes .slant-small-t::before,
.horoscopes .slant-small-b::before {
  background-color: #083064; }

.money .slant-small-t::before,
.money .slant-small-b::before {
  background-color: #451344; }

.moslive .slant-small-t::before,
.moslive .slant-small-b::before {
  background-color: #00b1a6; }

.motoring .slant-small-t::before,
.motoring .slant-small-b::before {
  background-color: #666; }

.news .slant-small-t::before,
.news .slant-small-b::before {
  background-color: #00aad2; }

.nochannel .slant-small-t::before,
.nochannel .slant-small-b::before {
  background-color: #000; }

.pictures .slant-small-t::before,
.pictures .slant-small-b::before {
  background-color: #f00; }

.property .slant-small-t::before,
.property .slant-small-b::before {
  background-color: #ac46c6; }

.royal_wedding .slant-small-t::before,
.royal_wedding .slant-small-b::before {
  background-color: #540c75; }

.sciencetech .slant-small-t::before,
.sciencetech .slant-small-b::before {
  background-color: #f60; }

.sport .slant-small-t::before,
.sport .slant-small-b::before {
  background-color: #0cac0c; }

.strayer .slant-small-t::before,
.strayer .slant-small-b::before {
  background-color: #b92025; }

.travel .slant-small-t::before,
.travel .slant-small-b::before {
  background-color: #082340; }

.tvshowbiz .slant-small-t::before,
.tvshowbiz .slant-small-b::before {
  background-color: #e01c44; }

.video .slant-small-t::before,
.video .slant-small-b::before {
  background-color: #004db4; }

.you .slant-small-t::before,
.you .slant-small-b::before {
  background-color: #d42699; }

.metro .slant-small-t::before,
.metro .slant-small-b::before {
  background-color: #ffc000; }

.home .slant-small-t::before,
.home .slant-small-b::before {
  background-color: #004db3; }

.have_your_say_module .slant-small-t::before,
.have_your_say_module .slant-small-b::before {
  background-color: #000; }

.article-smallslant .articletitle .slant-small-t::before {
  background-color: #fff; }

/**
* Horizontal carousel control status
*
* image-swap-carousel uses only gr4 colour
*/
.gamma-carousel div.paging-controls a,
.popup-carousel div.paging-controls a,
.info-carousel div.paging-controls a,
.image-swap-carousel div.paging-controls a {
  background-image: none;
  border-radius: 0 6px 6px 0;
  background-color: #999; }
  .gamma-carousel div.paging-controls a::after,
  .popup-carousel div.paging-controls a::after,
  .info-carousel div.paging-controls a::after,
  .image-swap-carousel div.paging-controls a::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -4px;
    width: 0;
    height: 0;
    border-width: 10px 0 10px 10px;
    border-style: solid;
    border-top-color: transparent;
    border-bottom-color: transparent;
    color: #fff; }

.gamma-carousel div.paging-controls a.previous,
.popup-carousel div.paging-controls a.previous,
.info-carousel div.paging-controls a.previous {
  border-radius: 6px 0 0 6px; }
  .gamma-carousel div.paging-controls a.previous::after,
  .popup-carousel div.paging-controls a.previous::after,
  .info-carousel div.paging-controls a.previous::after {
    margin: -10px 0 0 -6px;
    border-width: 10px 10px 10px 0; }

.image-swap-carousel div.paging-controls a {
  border-radius: 0 3px 3px 0; }
  .image-swap-carousel div.paging-controls a::after {
    margin: -5px 0 0 -2px;
    border-width: 5px 0 5px 5px; }

.image-swap-carousel div.paging-controls a.previous {
  border-radius: 3px 0 0 3px; }
  .image-swap-carousel div.paging-controls a.previous::after {
    margin: -5px 0 0 -3px;
    border-width: 5px 5px 5px 0; }

.coffeebreak .gamma-carousel div.paging-controls a,
.coffeebreak .popup-carousel div.paging-controls a,
.coffeebreak .info-carousel div.paging-controls a {
  background-color: #823d1e; }

.dailymailtv .gamma-carousel div.paging-controls a,
.dailymailtv .popup-carousel div.paging-controls a,
.dailymailtv .info-carousel div.paging-controls a {
  background-color: #004db3; }

.debate .gamma-carousel div.paging-controls a,
.debate .popup-carousel div.paging-controls a,
.debate .info-carousel div.paging-controls a {
  background-color: #a31414; }

.event .gamma-carousel div.paging-controls a,
.event .popup-carousel div.paging-controls a,
.event .info-carousel div.paging-controls a {
  background-color: #00b1a6; }

.femail .gamma-carousel div.paging-controls a,
.femail .popup-carousel div.paging-controls a,
.femail .info-carousel div.paging-controls a {
  background-color: #c562a5; }

.health .gamma-carousel div.paging-controls a,
.health .popup-carousel div.paging-controls a,
.health .info-carousel div.paging-controls a {
  background-color: #3cc; }

.horoscopes .gamma-carousel div.paging-controls a,
.horoscopes .popup-carousel div.paging-controls a,
.horoscopes .info-carousel div.paging-controls a {
  background-color: #083064; }

.money .gamma-carousel div.paging-controls a,
.money .popup-carousel div.paging-controls a,
.money .info-carousel div.paging-controls a {
  background-color: #451344; }

.moslive .gamma-carousel div.paging-controls a,
.moslive .popup-carousel div.paging-controls a,
.moslive .info-carousel div.paging-controls a {
  background-color: #00b1a6; }

.motoring .gamma-carousel div.paging-controls a,
.motoring .popup-carousel div.paging-controls a,
.motoring .info-carousel div.paging-controls a {
  background-color: #666; }

.news .gamma-carousel div.paging-controls a,
.news .popup-carousel div.paging-controls a,
.news .info-carousel div.paging-controls a {
  background-color: #00aad2; }

.nochannel .gamma-carousel div.paging-controls a,
.nochannel .popup-carousel div.paging-controls a,
.nochannel .info-carousel div.paging-controls a {
  background-color: #000; }

.pictures .gamma-carousel div.paging-controls a,
.pictures .popup-carousel div.paging-controls a,
.pictures .info-carousel div.paging-controls a {
  background-color: #f00; }

.property .gamma-carousel div.paging-controls a,
.property .popup-carousel div.paging-controls a,
.property .info-carousel div.paging-controls a {
  background-color: #ac46c6; }

.royal_wedding .gamma-carousel div.paging-controls a,
.royal_wedding .popup-carousel div.paging-controls a,
.royal_wedding .info-carousel div.paging-controls a {
  background-color: #540c75; }

.sciencetech .gamma-carousel div.paging-controls a,
.sciencetech .popup-carousel div.paging-controls a,
.sciencetech .info-carousel div.paging-controls a {
  background-color: #f60; }

.sport .gamma-carousel div.paging-controls a,
.sport .popup-carousel div.paging-controls a,
.sport .info-carousel div.paging-controls a {
  background-color: #0cac0c; }

.strayer .gamma-carousel div.paging-controls a,
.strayer .popup-carousel div.paging-controls a,
.strayer .info-carousel div.paging-controls a {
  background-color: #b92025; }

.travel .gamma-carousel div.paging-controls a,
.travel .popup-carousel div.paging-controls a,
.travel .info-carousel div.paging-controls a {
  background-color: #082340; }

.tvshowbiz .gamma-carousel div.paging-controls a,
.tvshowbiz .popup-carousel div.paging-controls a,
.tvshowbiz .info-carousel div.paging-controls a {
  background-color: #e01c44; }

.video .gamma-carousel div.paging-controls a,
.video .popup-carousel div.paging-controls a,
.video .info-carousel div.paging-controls a {
  background-color: #004db4; }

.you .gamma-carousel div.paging-controls a,
.you .popup-carousel div.paging-controls a,
.you .info-carousel div.paging-controls a {
  background-color: #d42699; }

.metro .gamma-carousel div.paging-controls a,
.metro .popup-carousel div.paging-controls a,
.metro .info-carousel div.paging-controls a {
  background-color: #ffc000; }

.home .gamma-carousel div.paging-controls a,
.home .popup-carousel div.paging-controls a,
.home .info-carousel div.paging-controls a {
  background-color: #004db3; }

/**
 * team colours definition
 *
 * format:
 * name hex
 *
 * original data taken from db:
 * select colour_name, colour_main_hex from colour where colour_type_id = 2 order by colour_order
 *
 * to extend list add a new entry
 */
/**
 * Basic (typogrphy) classes
 *
 * KEYS
 * o = on
 * w = white
 * tc = team colour
 * link = link colour
 * linkro = rollover
 *
 * i.e.
 * .arsenal .wotc - white text on arsenal colour background
 */
.arsenal .wotc,
.arsenal .link-wotc a,
.arsenal .linkro-wotc a:hover {
  color: #fff;
  background-color: #ef0007; }

.arsenal .tcow,
.arsenal .link-tcow a,
.arsenal .linkro-tcow a:hover {
  color: #ef0007;
  background-color: #fff; }

.blackburn-rovers .wotc,
.blackburn-rovers .link-wotc a,
.blackburn-rovers .linkro-wotc a:hover {
  color: #fff;
  background-color: #004cb6; }

.blackburn-rovers .tcow,
.blackburn-rovers .link-tcow a,
.blackburn-rovers .linkro-tcow a:hover {
  color: #004cb6;
  background-color: #fff; }

.bolton-wanderers .wotc,
.bolton-wanderers .link-wotc a,
.bolton-wanderers .linkro-wotc a:hover {
  color: #fff;
  background-color: #003; }

.bolton-wanderers .tcow,
.bolton-wanderers .link-tcow a,
.bolton-wanderers .linkro-tcow a:hover {
  color: #003;
  background-color: #fff; }

.charlton-athletic .wotc,
.charlton-athletic .link-wotc a,
.charlton-athletic .linkro-wotc a:hover {
  color: #fff;
  background-color: #e20000; }

.charlton-athletic .tcow,
.charlton-athletic .link-tcow a,
.charlton-athletic .linkro-tcow a:hover {
  color: #e20000;
  background-color: #fff; }

.everton .wotc,
.everton .link-wotc a,
.everton .linkro-wotc a:hover {
  color: #fff;
  background-color: #039; }

.everton .tcow,
.everton .link-tcow a,
.everton .linkro-tcow a:hover {
  color: #039;
  background-color: #fff; }

.fulham .wotc,
.fulham .link-wotc a,
.fulham .linkro-wotc a:hover {
  color: #fff;
  background-color: #000; }

.fulham .tcow,
.fulham .link-tcow a,
.fulham .linkro-tcow a:hover {
  color: #000;
  background-color: #fff; }

.liverpool .wotc,
.liverpool .link-wotc a,
.liverpool .linkro-wotc a:hover {
  color: #fff;
  background-color: #e20000; }

.liverpool .tcow,
.liverpool .link-tcow a,
.liverpool .linkro-tcow a:hover {
  color: #e20000;
  background-color: #fff; }

.manchester-united .wotc,
.manchester-united .link-wotc a,
.manchester-united .linkro-wotc a:hover {
  color: #fff;
  background-color: #c70102; }

.manchester-united .tcow,
.manchester-united .link-tcow a,
.manchester-united .linkro-tcow a:hover {
  color: #c70102;
  background-color: #fff; }

.newcastle-united .wotc,
.newcastle-united .link-wotc a,
.newcastle-united .linkro-wotc a:hover {
  color: #fff;
  background-color: #000; }

.newcastle-united .tcow,
.newcastle-united .link-tcow a,
.newcastle-united .linkro-tcow a:hover {
  color: #000;
  background-color: #fff; }

.portsmouth .wotc,
.portsmouth .link-wotc a,
.portsmouth .linkro-wotc a:hover {
  color: #fff;
  background-color: #0756a4; }

.portsmouth .tcow,
.portsmouth .link-tcow a,
.portsmouth .linkro-tcow a:hover {
  color: #0756a4;
  background-color: #fff; }

.reading .wotc,
.reading .link-wotc a,
.reading .linkro-wotc a:hover {
  color: #fff;
  background-color: #0035ad; }

.reading .tcow,
.reading .link-tcow a,
.reading .linkro-tcow a:hover {
  color: #0035ad;
  background-color: #fff; }

.sheffield-united .wotc,
.sheffield-united .link-wotc a,
.sheffield-united .linkro-wotc a:hover {
  color: #fff;
  background-color: #e20000; }

.sheffield-united .tcow,
.sheffield-united .link-tcow a,
.sheffield-united .linkro-tcow a:hover {
  color: #e20000;
  background-color: #fff; }

.tottenham-hotspur .wotc,
.tottenham-hotspur .link-wotc a,
.tottenham-hotspur .linkro-wotc a:hover {
  color: #fff;
  background-color: #21335a; }

.tottenham-hotspur .tcow,
.tottenham-hotspur .link-tcow a,
.tottenham-hotspur .linkro-tcow a:hover {
  color: #21335a;
  background-color: #fff; }

.watford .wotc,
.watford .link-wotc a,
.watford .linkro-wotc a:hover {
  color: #fff;
  background-color: #ffea00; }

.watford .tcow,
.watford .link-tcow a,
.watford .linkro-tcow a:hover {
  color: #ffea00;
  background-color: #fff; }

.west-ham-united .wotc,
.west-ham-united .link-wotc a,
.west-ham-united .linkro-wotc a:hover {
  color: #fff;
  background-color: #520c18; }

.west-ham-united .tcow,
.west-ham-united .link-tcow a,
.west-ham-united .linkro-tcow a:hover {
  color: #520c18;
  background-color: #fff; }

.wigan-athletic .wotc,
.wigan-athletic .link-wotc a,
.wigan-athletic .linkro-wotc a:hover {
  color: #fff;
  background-color: #0055a5; }

.wigan-athletic .tcow,
.wigan-athletic .link-tcow a,
.wigan-athletic .linkro-tcow a:hover {
  color: #0055a5;
  background-color: #fff; }

.aston-villa .wotc,
.aston-villa .link-wotc a,
.aston-villa .linkro-wotc a:hover {
  color: #fff;
  background-color: #7b013e; }

.aston-villa .tcow,
.aston-villa .link-tcow a,
.aston-villa .linkro-tcow a:hover {
  color: #7b013e;
  background-color: #fff; }

.manchester-city .wotc,
.manchester-city .link-wotc a,
.manchester-city .linkro-wotc a:hover {
  color: #fff;
  background-color: #7bb1dd; }

.manchester-city .tcow,
.manchester-city .link-tcow a,
.manchester-city .linkro-tcow a:hover {
  color: #7bb1dd;
  background-color: #fff; }

.birmingham-city .wotc,
.birmingham-city .link-wotc a,
.birmingham-city .linkro-wotc a:hover {
  color: #fff;
  background-color: #01009a; }

.birmingham-city .tcow,
.birmingham-city .link-tcow a,
.birmingham-city .linkro-tcow a:hover {
  color: #01009a;
  background-color: #fff; }

.chelsea .wotc,
.chelsea .link-wotc a,
.chelsea .linkro-wotc a:hover {
  color: #fff;
  background-color: #0a3a8d; }

.chelsea .tcow,
.chelsea .link-tcow a,
.chelsea .linkro-tcow a:hover {
  color: #0a3a8d;
  background-color: #fff; }

.derby-county .wotc,
.derby-county .link-wotc a,
.derby-county .linkro-wotc a:hover {
  color: #fff;
  background-color: #000; }

.derby-county .tcow,
.derby-county .link-tcow a,
.derby-county .linkro-tcow a:hover {
  color: #000;
  background-color: #fff; }

.celtic .wotc,
.celtic .link-wotc a,
.celtic .linkro-wotc a:hover {
  color: #fff;
  background-color: #1aaf2f; }

.celtic .tcow,
.celtic .link-tcow a,
.celtic .linkro-tcow a:hover {
  color: #1aaf2f;
  background-color: #fff; }

.rangers .wotc,
.rangers .link-wotc a,
.rangers .linkro-wotc a:hover {
  color: #fff;
  background-color: #1222ac; }

.rangers .tcow,
.rangers .link-tcow a,
.rangers .linkro-tcow a:hover {
  color: #1222ac;
  background-color: #fff; }

.middlesbrough .wotc,
.middlesbrough .link-wotc a,
.middlesbrough .linkro-wotc a:hover {
  color: #fff;
  background-color: #e70101; }

.middlesbrough .tcow,
.middlesbrough .link-tcow a,
.middlesbrough .linkro-tcow a:hover {
  color: #e70101;
  background-color: #fff; }

.sunderland .wotc,
.sunderland .link-wotc a,
.sunderland .linkro-wotc a:hover {
  color: #fff;
  background-color: #ea0202; }

.sunderland .tcow,
.sunderland .link-tcow a,
.sunderland .linkro-tcow a:hover {
  color: #ea0202;
  background-color: #fff; }

.hull-city .wotc,
.hull-city .link-wotc a,
.hull-city .linkro-wotc a:hover {
  color: #fff;
  background-color: #efae08; }

.hull-city .tcow,
.hull-city .link-tcow a,
.hull-city .linkro-tcow a:hover {
  color: #efae08;
  background-color: #fff; }

.stoke-city .wotc,
.stoke-city .link-wotc a,
.stoke-city .linkro-wotc a:hover {
  color: #fff;
  background-color: #e2373e; }

.stoke-city .tcow,
.stoke-city .link-tcow a,
.stoke-city .linkro-tcow a:hover {
  color: #e2373e;
  background-color: #fff; }

.west-bromwich-albion .wotc,
.west-bromwich-albion .link-wotc a,
.west-bromwich-albion .linkro-wotc a:hover {
  color: #fff;
  background-color: #091453; }

.west-bromwich-albion .tcow,
.west-bromwich-albion .link-tcow a,
.west-bromwich-albion .linkro-tcow a:hover {
  color: #091453;
  background-color: #fff; }

.blackpool .wotc,
.blackpool .link-wotc a,
.blackpool .linkro-wotc a:hover {
  color: #fff;
  background-color: #ff6500; }

.blackpool .tcow,
.blackpool .link-tcow a,
.blackpool .linkro-tcow a:hover {
  color: #ff6500;
  background-color: #fff; }

.wolverhampton-wanderers .wotc,
.wolverhampton-wanderers .link-wotc a,
.wolverhampton-wanderers .linkro-wotc a:hover {
  color: #fff;
  background-color: #ee8d22; }

.wolverhampton-wanderers .tcow,
.wolverhampton-wanderers .link-tcow a,
.wolverhampton-wanderers .linkro-tcow a:hover {
  color: #ee8d22;
  background-color: #fff; }

.burnley .wotc,
.burnley .link-wotc a,
.burnley .linkro-wotc a:hover {
  color: #fff;
  background-color: #903; }

.burnley .tcow,
.burnley .link-tcow a,
.burnley .linkro-tcow a:hover {
  color: #903;
  background-color: #fff; }

.norwich-city .wotc,
.norwich-city .link-wotc a,
.norwich-city .linkro-wotc a:hover {
  color: #fff;
  background-color: #287148; }

.norwich-city .tcow,
.norwich-city .link-tcow a,
.norwich-city .linkro-tcow a:hover {
  color: #287148;
  background-color: #fff; }

.queens-park-rangers .wotc,
.queens-park-rangers .link-wotc a,
.queens-park-rangers .linkro-wotc a:hover {
  color: #fff;
  background-color: #2929bd; }

.queens-park-rangers .tcow,
.queens-park-rangers .link-tcow a,
.queens-park-rangers .linkro-tcow a:hover {
  color: #2929bd;
  background-color: #fff; }

.swansea-city .wotc,
.swansea-city .link-wotc a,
.swansea-city .linkro-wotc a:hover {
  color: #fff;
  background-color: #000; }

.swansea-city .tcow,
.swansea-city .link-tcow a,
.swansea-city .linkro-tcow a:hover {
  color: #000;
  background-color: #fff; }

.switzerland .wotc,
.switzerland .link-wotc a,
.switzerland .linkro-wotc a:hover {
  color: #fff;
  background-color: #f00; }

.switzerland .tcow,
.switzerland .link-tcow a,
.switzerland .linkro-tcow a:hover {
  color: #f00;
  background-color: #fff; }

.unitedstates .wotc,
.unitedstates .link-wotc a,
.unitedstates .linkro-wotc a:hover {
  color: #fff;
  background-color: #000040; }

.unitedstates .tcow,
.unitedstates .link-tcow a,
.unitedstates .linkro-tcow a:hover {
  color: #000040;
  background-color: #fff; }

.uruguay .wotc,
.uruguay .link-wotc a,
.uruguay .linkro-wotc a:hover {
  color: #fff;
  background-color: #7fbeff; }

.uruguay .tcow,
.uruguay .link-tcow a,
.uruguay .linkro-tcow a:hover {
  color: #7fbeff;
  background-color: #fff; }

.algeria .wotc,
.algeria .link-wotc a,
.algeria .linkro-wotc a:hover {
  color: #fff;
  background-color: #5b6; }

.algeria .tcow,
.algeria .link-tcow a,
.algeria .linkro-tcow a:hover {
  color: #5b6;
  background-color: #fff; }

.argentina .wotc,
.argentina .link-wotc a,
.argentina .linkro-wotc a:hover {
  color: #fff;
  background-color: #80bfff; }

.argentina .tcow,
.argentina .link-tcow a,
.argentina .linkro-tcow a:hover {
  color: #80bfff;
  background-color: #fff; }

.australia .wotc,
.australia .link-wotc a,
.australia .linkro-wotc a:hover {
  color: #fff;
  background-color: #ffbe00; }

.australia .tcow,
.australia .link-tcow a,
.australia .linkro-tcow a:hover {
  color: #ffbe00;
  background-color: #fff; }

.brazil .wotc,
.brazil .link-wotc a,
.brazil .linkro-wotc a:hover {
  color: #fff;
  background-color: #0065b5; }

.brazil .tcow,
.brazil .link-tcow a,
.brazil .linkro-tcow a:hover {
  color: #0065b5;
  background-color: #fff; }

.cameroon .wotc,
.cameroon .link-wotc a,
.cameroon .linkro-wotc a:hover {
  color: #fff;
  background-color: #008000; }

.cameroon .tcow,
.cameroon .link-tcow a,
.cameroon .linkro-tcow a:hover {
  color: #008000;
  background-color: #fff; }

.chile .wotc,
.chile .link-wotc a,
.chile .linkro-wotc a:hover {
  color: #fff;
  background-color: #e21c1d; }

.chile .tcow,
.chile .link-tcow a,
.chile .linkro-tcow a:hover {
  color: #e21c1d;
  background-color: #fff; }

.denmark .wotc,
.denmark .link-wotc a,
.denmark .linkro-wotc a:hover {
  color: #fff;
  background-color: #df0000; }

.denmark .tcow,
.denmark .link-tcow a,
.denmark .linkro-tcow a:hover {
  color: #df0000;
  background-color: #fff; }

.england .wotc,
.england .link-wotc a,
.england .linkro-wotc a:hover {
  color: #fff;
  background-color: #f00; }

.england .tcow,
.england .link-tcow a,
.england .linkro-tcow a:hover {
  color: #f00;
  background-color: #fff; }

.france .wotc,
.france .link-wotc a,
.france .linkro-wotc a:hover {
  color: #fff;
  background-color: #000080; }

.france .tcow,
.france .link-tcow a,
.france .linkro-tcow a:hover {
  color: #000080;
  background-color: #fff; }

.germany .wotc,
.germany .link-wotc a,
.germany .linkro-wotc a:hover {
  color: #fff;
  background-color: #000; }

.germany .tcow,
.germany .link-tcow a,
.germany .linkro-tcow a:hover {
  color: #000;
  background-color: #fff; }

.ghana .wotc,
.ghana .link-wotc a,
.ghana .linkro-wotc a:hover {
  color: #fff;
  background-color: #f00; }

.ghana .tcow,
.ghana .link-tcow a,
.ghana .linkro-tcow a:hover {
  color: #f00;
  background-color: #fff; }

.greece .wotc,
.greece .link-wotc a,
.greece .linkro-wotc a:hover {
  color: #fff;
  background-color: #2b54ae; }

.greece .tcow,
.greece .link-tcow a,
.greece .linkro-tcow a:hover {
  color: #2b54ae;
  background-color: #fff; }

.honduras .wotc,
.honduras .link-wotc a,
.honduras .linkro-wotc a:hover {
  color: #fff;
  background-color: #18397e; }

.honduras .tcow,
.honduras .link-tcow a,
.honduras .linkro-tcow a:hover {
  color: #18397e;
  background-color: #fff; }

.italy .wotc,
.italy .link-wotc a,
.italy .linkro-wotc a:hover {
  color: #fff;
  background-color: #395fc2; }

.italy .tcow,
.italy .link-tcow a,
.italy .linkro-tcow a:hover {
  color: #395fc2;
  background-color: #fff; }

.ivorycoast .wotc,
.ivorycoast .link-wotc a,
.ivorycoast .linkro-wotc a:hover {
  color: #fff;
  background-color: #f49517; }

.ivorycoast .tcow,
.ivorycoast .link-tcow a,
.ivorycoast .linkro-tcow a:hover {
  color: #f49517;
  background-color: #fff; }

.japan .wotc,
.japan .link-wotc a,
.japan .linkro-wotc a:hover {
  color: #fff;
  background-color: #202a5b; }

.japan .tcow,
.japan .link-tcow a,
.japan .linkro-tcow a:hover {
  color: #202a5b;
  background-color: #fff; }

.koreadpr .wotc,
.koreadpr .link-wotc a,
.koreadpr .linkro-wotc a:hover {
  color: #fff;
  background-color: #d62e47; }

.koreadpr .tcow,
.koreadpr .link-tcow a,
.koreadpr .linkro-tcow a:hover {
  color: #d62e47;
  background-color: #fff; }

.korearepublic .wotc,
.korearepublic .link-wotc a,
.korearepublic .linkro-wotc a:hover {
  color: #fff;
  background-color: #ff0040; }

.korearepublic .tcow,
.korearepublic .link-tcow a,
.korearepublic .linkro-tcow a:hover {
  color: #ff0040;
  background-color: #fff; }

.mexico .wotc,
.mexico .link-wotc a,
.mexico .linkro-wotc a:hover {
  color: #fff;
  background-color: #00a150; }

.mexico .tcow,
.mexico .link-tcow a,
.mexico .linkro-tcow a:hover {
  color: #00a150;
  background-color: #fff; }

.netherlands .wotc,
.netherlands .link-wotc a,
.netherlands .linkro-wotc a:hover {
  color: #fff;
  background-color: #ff5f00; }

.netherlands .tcow,
.netherlands .link-tcow a,
.netherlands .linkro-tcow a:hover {
  color: #ff5f00;
  background-color: #fff; }

.newzealand .wotc,
.newzealand .link-wotc a,
.newzealand .linkro-wotc a:hover {
  color: #fff;
  background-color: #000; }

.newzealand .tcow,
.newzealand .link-tcow a,
.newzealand .linkro-tcow a:hover {
  color: #000;
  background-color: #fff; }

.nigeria .wotc,
.nigeria .link-wotc a,
.nigeria .linkro-wotc a:hover {
  color: #fff;
  background-color: #008000; }

.nigeria .tcow,
.nigeria .link-tcow a,
.nigeria .linkro-tcow a:hover {
  color: #008000;
  background-color: #fff; }

.paraguay .wotc,
.paraguay .link-wotc a,
.paraguay .linkro-wotc a:hover {
  color: #fff;
  background-color: #d20000; }

.paraguay .tcow,
.paraguay .link-tcow a,
.paraguay .linkro-tcow a:hover {
  color: #d20000;
  background-color: #fff; }

.portugal .wotc,
.portugal .link-wotc a,
.portugal .linkro-wotc a:hover {
  color: #fff;
  background-color: #f00; }

.portugal .tcow,
.portugal .link-tcow a,
.portugal .linkro-tcow a:hover {
  color: #f00;
  background-color: #fff; }

.serbia .wotc,
.serbia .link-wotc a,
.serbia .linkro-wotc a:hover {
  color: #fff;
  background-color: #e11; }

.serbia .tcow,
.serbia .link-tcow a,
.serbia .linkro-tcow a:hover {
  color: #e11;
  background-color: #fff; }

.slovakia .wotc,
.slovakia .link-wotc a,
.slovakia .linkro-wotc a:hover {
  color: #fff;
  background-color: #0000df; }

.slovakia .tcow,
.slovakia .link-tcow a,
.slovakia .linkro-tcow a:hover {
  color: #0000df;
  background-color: #fff; }

.slovenia .wotc,
.slovenia .link-wotc a,
.slovenia .linkro-wotc a:hover {
  color: #fff;
  background-color: #004000; }

.slovenia .tcow,
.slovenia .link-tcow a,
.slovenia .linkro-tcow a:hover {
  color: #004000;
  background-color: #fff; }

.southafrica .wotc,
.southafrica .link-wotc a,
.southafrica .linkro-wotc a:hover {
  color: #fff;
  background-color: #388b71; }

.southafrica .tcow,
.southafrica .link-tcow a,
.southafrica .linkro-tcow a:hover {
  color: #388b71;
  background-color: #fff; }

.spain .wotc,
.spain .link-wotc a,
.spain .linkro-wotc a:hover {
  color: #fff;
  background-color: #e80113; }

.spain .tcow,
.spain .link-tcow a,
.spain .linkro-tcow a:hover {
  color: #e80113;
  background-color: #fff; }

.cardiff-city .wotc,
.cardiff-city .link-wotc a,
.cardiff-city .linkro-wotc a:hover {
  color: #fff;
  background-color: #d41124; }

.cardiff-city .tcow,
.cardiff-city .link-tcow a,
.cardiff-city .linkro-tcow a:hover {
  color: #d41124;
  background-color: #fff; }

.crystal-palace .wotc,
.crystal-palace .link-wotc a,
.crystal-palace .linkro-wotc a:hover {
  color: #fff;
  background-color: #3814d0; }

.crystal-palace .tcow,
.crystal-palace .link-tcow a,
.crystal-palace .linkro-tcow a:hover {
  color: #3814d0;
  background-color: #fff; }

.southampton .wotc,
.southampton .link-wotc a,
.southampton .linkro-wotc a:hover {
  color: #fff;
  background-color: #d41124; }

.southampton .tcow,
.southampton .link-tcow a,
.southampton .linkro-tcow a:hover {
  color: #d41124;
  background-color: #fff; }

.huddersfield-town .wotc,
.huddersfield-town .link-wotc a,
.huddersfield-town .linkro-wotc a:hover {
  color: #fff;
  background-color: #005daa; }

.huddersfield-town .tcow,
.huddersfield-town .link-tcow a,
.huddersfield-town .linkro-tcow a:hover {
  color: #005daa;
  background-color: #fff; }

.brighton-and-hove-albion .wotc,
.brighton-and-hove-albion .link-wotc a,
.brighton-and-hove-albion .linkro-wotc a:hover {
  color: #fff;
  background-color: #005daa; }

.brighton-and-hove-albion .tcow,
.brighton-and-hove-albion .link-tcow a,
.brighton-and-hove-albion .linkro-tcow a:hover {
  color: #005daa;
  background-color: #fff; }

.article-text {
  font-family: 'graphik', Arial, sans-serif; }
  .article-text * {
    letter-spacing: -0.01em; }
  .article-text h1 {
    font-size: 34px;
    line-height: 40px;
    margin: 0 0 4px; }
    .gamma .article-text h1 {
      font-size: 38px;
      line-height: 42px; }
  .article-text h2 {
    margin: 0 0 16px;
    font-size: 1.1em; }
  .article-text h3 {
    margin-bottom: 10px;
    padding: 4px 8px;
    font-size: 1.1em;
    background-color: #333;
    color: #fff; }
  .article-text p {
    min-height: 0; }
    .article-text p.scrollText {
      font-weight: bold;
      margin: 0 0 2px; }
  .article-text ul,
  .article-text ol {
    margin-bottom: 8px;
    padding: 4px 0 4px 16px; }
    .article-text ul li,
    .article-text ol li {
      padding: 2px 0; }
  .article-text ul {
    list-style-type: disc; }
    .article-text ul li {
      list-style: disc; }
  .article-text ol {
    list-style-type: decimal; }
    .article-text ol li {
      list-style: decimal; }
  .article-text a:hover {
    text-decoration: underline; }

@media screen and (-moz-windows-theme) {
  .article-text * {
    letter-spacing: 0 !important;
    font-kerning: none; } }

.article-text {
  /*
  As CC adds inline font-size to font and span elements we have to
   - reset the font-size on those element using !important
   - use a more specific selector (in conjunction with !importan) to apply any expected 'transformation' of the size
  */ }
  .article-text font,
  .article-text p span {
    font-size: 1em !important; }
  .article-text .mol-style-bold {
    font-weight: bold; }
  .article-text .mol-style-italic {
    font-style: italic; }
  .article-text .mol-style-underline {
    text-decoration: underline; }
  .article-text .mol-style-smaller {
    font-size: 0.6em !important;
    line-height: 1.5em; }
  .article-text .mol-style-small {
    font-size: 0.8em !important;
    line-height: 1.5em; }
  .article-text .mol-style-medium {
    font-size: 1.2em !important;
    line-height: 1.5em; }
  .article-text .mol-style-large {
    font-size: 1.4em !important;
    line-height: 1.5em; }
  .article-text .mol-style-larger {
    font-size: 1.8em !important;
    line-height: 1.5em; }
  .article-text .mol-style-subhead {
    font-size: 1.7em !important;
    line-height: 1.5em;
    font-weight: bold; }
  .article-text .mol-style-blobs {
    font-size: 1.4em !important;
    font-weight: bold; }
  .article-text .mol-style-caption {
    position: relative;
    top: -5px; }

.mol-color-black {
  color: #000; }

.mol-color-oreg {
  color: #930; }

.mol-color-madr {
  color: #330; }

.mol-color-deep_fir {
  color: #030; }

.mol-color-midnight_blue {
  color: #036; }

.mol-color-navy {
  color: #000080; }

.mol-color-blue_pigment {
  color: #339; }

.mol-color-mine_shaf {
  color: #333; }

.mol-color-maro {
  color: #800000; }

.mol-color-blaze_orange {
  color: #f60; }

.mol-color-olive {
  color: #808000; }

.mol-color-green {
  color: #008000; }

.mol-color-teal {
  color: #008080; }

.mol-color-blue {
  color: #00f; }

.mol-color-kimberly {
  color: #669; }

.mol-color-gray {
  color: #808080; }

.mol-color-r {
  color: #f00; }

.mol-color-orange_pe {
  color: #f90; }

.mol-color-pistachio {
  color: #9c0; }

.mol-color-sea_green {
  color: #396; }

.mol-color-turquoise {
  color: #3cc; }

.mol-color-ultramarine_bl {
  color: #36f; }

.mol-color-purp {
  color: #800080; }

.mol-color-mountain_mist {
  color: #969696; }

.mol-color-magenta {
  color: #f0f; }

.mol-color-tangerine_yell {
  color: #fc0; }

.mol-color-yell {
  color: #ff0; }

.mol-color-lime {
  color: #0f0; }

.mol-color-aqua {
  color: #0ff; }

.mol-color-capri {
  color: #0cf; }

.mol-color-vin_rouge {
  color: #936; }

.mol-color-silv {
  color: #c0c0c0; }

.mol-color-carnation_pink {
  color: #f9c; }

.mol-color-peach_orange {
  color: #fc9; }

.mol-color-pale_cana {
  color: #ff9; }

.mol-color-snowy_min {
  color: #cfc; }

.mol-color-onah {
  color: #cff; }

.mol-color-baby_blue_eyes {
  color: #9cf; }

.mol-color-mauve {
  color: #c9f; }

.mol-color-white {
  color: #fff; }

.article-text > ul:first-of-type {
  /* article heading list */
  font-size: 16px;
  line-height: 22px;
  /*
    this is need to until http://andjira.and.dmgt.net:8080/browse/MOL-10147
    is closed as CC is inserting spans with inlined font-size
    */ }
  .gamma .article-text > ul:first-of-type {
    font-size: 18px;
    line-height: 24px; }
  .article-text > ul:first-of-type span {
    font-size: 1em !important; }

.article-text .sponsored-by,
.article-text .cc-asset-content .sponsored-by {
  font-size: 17px; }

.article-text .byline-plain,
.article-text .byline-fancy {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 4px; }
  .article-text .byline-plain a,
  .article-text .byline-fancy a {
    color: #004db3; }
  .gamma .article-text .byline-plain, .gamma
  .article-text .byline-fancy {
    font-size: 16px; }

.article-text .author-section {
  padding-top: 11px;
  border-top: 1px solid #e3e3e3; }

.article-text .byline-plain .author,
.article-text .byline-fancy .author,
.article-text .byline-fancy .paper {
  text-transform: uppercase; }

.article-text .byline-section {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;
  color: #666; }

.article-text #articleIconLinksContainer {
  margin-bottom: 16px;
  padding: 4px 0;
  border-color: #e3e3e3; }
  .article-text #articleIconLinksContainer .share-count span {
    font-size: 12px;
    line-height: 1; }
  .article-text #articleIconLinksContainer .comments-count .count-number {
    height: 32px; }
  .article-text #articleIconLinksContainer .comments-count .count-text {
    width: 96px; }

.article-text.cc-asset-content .byline-plain {
  margin-bottom: 4px; }

.article-text.cc-asset-content .byline-section {
  margin-bottom: 8px; }

.shareArticles h3.social-links-title {
  border-color: #e3e3e3; }

/* remove .article-text.cc-asset-content when cc will use the same structure of the real webpage */
div[itemprop=articleBody],
.article-text.cc-asset-content {
  font-size: 16px;
  line-height: 23px; }
  div[itemprop=articleBody] p,
  .article-text.cc-asset-content p {
    margin: 0 0 16px; }
  .gamma div[itemprop=articleBody], .gamma
  .article-text.cc-asset-content {
    font-size: 18px;
    line-height: 26px; }
    .gamma div[itemprop=articleBody] p, .gamma
    .article-text.cc-asset-content p {
      margin: 0 0 18px; }

/* remove .article-text.cc-asset-content when cc will use the same structure of the real webpage */
div[itemprop=articleBody],
.article-text.cc-asset-content {
  /* Legacy tables */ }
  div[itemprop=articleBody] table,
  .article-text.cc-asset-content table {
    width: 100%;
    font-size: 12px;
    margin: 0 0 16px; }
  div[itemprop=articleBody] .dataTable,
  div[itemprop=articleBody] .mol-table,
  .article-text.cc-asset-content .dataTable,
  .article-text.cc-asset-content .mol-table {
    border-collapse: separate; }
    div[itemprop=articleBody] .dataTable caption,
    div[itemprop=articleBody] .mol-table caption,
    .article-text.cc-asset-content .dataTable caption,
    .article-text.cc-asset-content .mol-table caption {
      font-size: 16px;
      font-weight: bold;
      padding: 5px; }
    div[itemprop=articleBody] .dataTable th,
    div[itemprop=articleBody] .dataTable td,
    div[itemprop=articleBody] .mol-table th,
    div[itemprop=articleBody] .mol-table td,
    .article-text.cc-asset-content .dataTable th,
    .article-text.cc-asset-content .dataTable td,
    .article-text.cc-asset-content .mol-table th,
    .article-text.cc-asset-content .mol-table td {
      padding: 5px; }
    div[itemprop=articleBody] .dataTable th,
    div[itemprop=articleBody] .mol-table th,
    .article-text.cc-asset-content .dataTable th,
    .article-text.cc-asset-content .mol-table th {
      background: #999;
      color: white;
      font-weight: bold; }
      div[itemprop=articleBody] .dataTable th.noHead,
      div[itemprop=articleBody] .mol-table th.noHead,
      .article-text.cc-asset-content .dataTable th.noHead,
      .article-text.cc-asset-content .mol-table th.noHead {
        display: none; }
    div[itemprop=articleBody] .dataTable td,
    div[itemprop=articleBody] .mol-table td,
    .article-text.cc-asset-content .dataTable td,
    .article-text.cc-asset-content .mol-table td {
      background: #f5f5f5;
      border-bottom: 1px solid #c0c0c0;
      border-top: 1px solid #fff; }
  div[itemprop=articleBody] .dataTable caption,
  .article-text.cc-asset-content .dataTable caption {
    background: #af1e1e;
    color: #fff; }
  div[itemprop=articleBody] .article-table caption,
  .article-text.cc-asset-content .article-table caption {
    text-align: right;
    font-size: 0.92em;
    margin-bottom: 2px; }
  div[itemprop=articleBody] .article-table td,
  div[itemprop=articleBody] .article-table th,
  .article-text.cc-asset-content .article-table td,
  .article-text.cc-asset-content .article-table th {
    border: 1px solid #c0c0c0;
    padding: 2px; }
  div[itemprop=articleBody] .article-table-header td,
  div[itemprop=articleBody] .article-table-header th,
  .article-text.cc-asset-content .article-table-header td,
  .article-text.cc-asset-content .article-table-header th {
    font-weight: bold;
    text-align: center; }

.article-text .imageCaption {
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px solid #e3e3e3;
  padding: 4px 0;
  margin: 0 0 16px;
  text-align: left;
  font-weight: bold;
  clear: both; }
  .gamma .article-text .imageCaption {
    font-size: 16px;
    line-height: 21px; }

.mol-hidden-caption .imageCaption {
  display: none; }

.article-text .item {
  font-size: 12px;
  margin-bottom: 16px; }

.article-text .moduleFull,
.article-text .moduleTriple {
  clear: both;
  margin: 0 0 16px; }

.article-text .moduleHalf {
  float: right;
  width: 308px;
  margin: 0 0 16px 10px; }
  .artSplitter .article-text .moduleHalf {
    margin: 0; }
  .floatRHS .article-text .moduleHalf {
    float: none; }

.article-text .mol-col .mol-factbox[data-version="2"] .moduleHalf,
.article-text .mol-factbox[data-version="2"].floatLHS .moduleHalf,
.article-text .mol-factbox[data-version="2"].floatRHS .moduleHalf,
.article-text .mol-factbox[data-version="2"] .mol-col .moduleHalf {
  width: 100%; }

.article-text .moduleThinArticle {
  clear: both;
  margin: 0 0 16px; }

.mol-video .item {
  margin-bottom: 0; }

.mol-columnist {
  font-size: 1.2em; }

.social-links {
  font-size: 12px; }

/*
    For fixing when there is a image floated near a image group in cc
 */
.xwArtSplitter,
.artSplitter,
.thinArtSplitter {
  clear: both; }

.mol-article-quote {
  border-top: solid 1px #e3e3e3;
  border-bottom: solid 1px #e3e3e3;
  margin: 16px 0;
  padding-bottom: 8px;
  /* to compensate background position bottom of the wrapper */
  clear: both; }

.mol-article-quote.floatRHS {
  clear: right; }

.mol-article-quote.floatLHS {
  clear: left; }

.mol-article-quote-wrapper {
  position: relative;
  padding: 10px 23px 6px;
  font-size: 20px;
  font-style: italic; }
  .mol-article-quote-wrapper::before, .mol-article-quote-wrapper::after {
    content: "\201C";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 14px;
    height: 14px;
    font-size: 45px;
    font-weight: bold;
    font-style: normal;
    line-height: 1em; }
  .mol-article-quote-wrapper::after {
    content: "\201D";
    left: auto;
    top: auto;
    right: 6px;
    bottom: 8px; }

.mol-article-quote-attribution {
  font-size: 14px;
  padding: 10px 23px 6px;
  font-weight: bold;
  color: #000; }

.mol-gif img {
  width: 100%;
  height: auto; }

.mol-cols {
  width: 100%;
  clear: both;
  margin-bottom: 8px; }

.mol-cols .mol-col .mol-col-content,
.mol-cols .mol-col .mol-col-content img {
  max-width: 100%; }

.mol-col .mol-col-content {
  padding-right: 0; }

.mol-col {
  display: inline-block;
  vertical-align: top; }

.mol-cols .mol-col .mol-img-group,
.mol-cols .mol-col .mol-img-group .imageCaption {
  width: auto; }

.mol-embed,
.mol-html,
.mol-ratings,
.mol-columnist-module,
.mol-gif {
  margin-bottom: 8px; }

/* Below we generate all the possible column content margin gaps (space between columns) for all the possible column compilations */
.mol-cols-1 .mol-col-1 .mol-col-content {
  margin-left: 0px;
  margin-right: 0px; }

.mol-cols-2 .mol-col-1 .mol-col-content {
  margin-left: 0px;
  margin-right: 5px; }

.mol-cols-2 .mol-col-2 .mol-col-content {
  margin-left: 5px;
  margin-right: 0px; }

.mol-cols-3 .mol-col-1 .mol-col-content {
  margin-left: 0px;
  margin-right: 6.66667px; }

.mol-cols-3 .mol-col-2 .mol-col-content {
  margin-left: 3.33333px;
  margin-right: 3.33333px; }

.mol-cols-3 .mol-col-3 .mol-col-content {
  margin-left: 6.66667px;
  margin-right: 0px; }

.mol-cols-4 .mol-col-1 .mol-col-content {
  margin-left: 0px;
  margin-right: 7.5px; }

.mol-cols-4 .mol-col-2 .mol-col-content {
  margin-left: 2.5px;
  margin-right: 5px; }

.mol-cols-4 .mol-col-3 .mol-col-content {
  margin-left: 5px;
  margin-right: 2.5px; }

.mol-cols-4 .mol-col-4 .mol-col-content {
  margin-left: 7.5px;
  margin-right: 0px; }

[data-accordion-controller].mol-accordion-open + [data-accordion-id] .mol-accordion-wrapper {
  max-height: 500px;
  overflow: scroll;
  opacity: 1; }

.mol-table [data-accordion-controller] td {
  transition: all 0.5s;
  cursor: pointer; }

.mol-table [data-accordion-id] td {
  background-color: #fff;
  border: 0;
  padding: 0; }

[data-accordion-id] .mol-accordion-wrapper {
  max-height: 0;
  transform: translateZ(0);
  opacity: 0;
  transition: max-height 0.5s, opacity 0.75s;
  overflow: hidden; }

.mol-style-align-left {
  text-align: left; }

.mol-style-align-center {
  text-align: center; }

.mol-style-align-right {
  text-align: right; }

.branded-content {
  border-top: 1px solid #00aad2;
  border-bottom: 1px solid #00aad2;
  font-style: normal;
  font-weight: bold; }

.mol-mobile .branded-content__title {
  padding-left: 10px; }

.branded-content__title {
  padding: 0;
  margin: 0;
  width: 84px;
  line-height: 32px;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  color: #00aad2; }

.branded-content__description {
  text-align: left;
  background-color: #f0f0f0;
  padding: 10px;
  font-size: 16px;
  line-height: 19px; }
  .branded-content__description a {
    color: #004db3; }
