.footer .nav-primary li {
  position: static;
}
.footer .nav-primary li {
  position: static;
  float: left;
}
.footer {
  margin-bottom:15px;
}
.footer .nav-primary {
  padding: 0;
}
.footer .dm-tabs li  {
  margin-right: 5px;
}
.footer .dm-tabs li  a {
  padding: 4px 8px 7px;
  font-size: 1.5em;
}
.footer .dm-tab-on {
  display: none;
}
/*.footer div.page-header {
  margin-top:0;
}*/